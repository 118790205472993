<template>
  <div class="round">
    <div class="round-head">
      <div class="round-name">{{ roundData.roundName }}</div>
      <div class="round-time"><span class="t-name">Registration:</span>15 April - 18 April 2024 (GMT+3)</div>
      <div class="round-time"><span class="t-name">Duration:</span>15 April - 19 April 2024 (GMT+3)</div>
    </div>
    <div class="round-list-wrap">
      <RoundList :userList="roundData.profitLeader" title="Top Gainer Leaderboard" />
      <RoundList :userList="roundData.VolumeLeader" title="Top Volume Leaderboard" />
    </div>
  </div>
</template>

<script>
import RoundList from './RoundList.vue'
export default {
  name: 'Round',
  components: {
    RoundList,
  },
  props: {
    roundData: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    console.log('----this.roundData:', this.roundData)
  }
}
</script>

<style lang="scss" scoped>
.round {
  margin: 0 auto;
  width: 1340px;
  height: 700px;
  border-radius: 16px;
  background: rgba(248, 248, 248, 0.9);
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.25);
  padding: 48px 50px;
  .round-head {
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 2px solid var(--theme-minor-color);
    .round-name {
      font-size: 32px;
      font-weight: 600;
      color: var(--theme-minor-color);
      line-height: 40px;
    }
    .round-time {
      text-align: center;
      flex: 1;
      margin-left: 50px;
      font-weight: 700;
      font-size: 20px;
      color:#1E174F;
      line-height: 24px;
      border-left: 1px solid #CCC;
      .t-name {
        font-weight: 500;
        font-size: 20px;
        color:#1E174F;
        line-height: 24px;
      }
    }
  }
  .round-list-wrap {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 60px;
  }
}
</style>