<template>
  <div class="mh-steps">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Mh-Steps'
}
</script>

<style lang="scss" scoped>
.mh-steps {
  height: 100%;
  display: flex;
  flex-flow: column;
}
</style>