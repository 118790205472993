<template>
  <div class="mh-dropdown">
    <div class="mh-dropdown-toggle mh-flex-center" @click="handleDropdown">
      <div class="mh-dropdown-text">{{ mapText[this.selected] }}</div>
      <i class="chevron bi bi-chevron-up"></i>
    </div>
    <div class="mh-dropdown-menu" v-if="showDropdownMenu" @click="handleSelected">
      <div :class="['mh-dropdown-item', { 'active': selected === 1 }]" data-index="1">INFORMATION</div>
      <div :class="['mh-dropdown-item', { 'active': selected === 2 }]" data-index="2">RULES</div>
      <div :class="['mh-dropdown-item', { 'active': selected === 3 }]" data-index="3">CURRENT ROUND</div>
      <div :class="['mh-dropdown-item', { 'active': selected === 4 }]" data-index="4">ARCHIVE</div>
      <div :class="['mh-dropdown-item', { 'active': selected === 5 }]" data-index="5">ABOUT US</div>
    </div>
  </div>
</template>

<script>
const mapText = {
  1: 'INFORMATION',
  2: 'RULES',
  3: 'CURRENT ROUND',
  4: 'ARCHIVE',
  5: 'ABOUT US'
}
export default {
  name: 'MhDropdown',
  props: {
    selected: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      mapText,
      showDropdownMenu: false
    }
  },
  methods: {
    handleDropdown() {
      this.showDropdownMenu = !this.showDropdownMenu
    },
    handleSelected(event) {
      const target = event.target
      if (event.target.className === 'mh-dropdown-menu') return
      const index = Number(target.dataset.index)
      if (index !== this.selected) {
        this.selectedText = target.textContent
        this.$emit('update:selected', index)
      }
      this.showDropdownMenu = false
    }
  },
}
</script>

<style lang="scss" scoped>
.mh-dropdown {
  position: relative;
  margin: 0 auto;
  width: 76.92%;
  .mh-dropdown-toggle {
    width: 100%;
    height: 80px;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    .mh-dropdown-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #C6222F;
    }
    .chevron {
      margin-left: 10px;
      color: #C6222F;
    }
  }
  .mh-dropdown-menu {
    user-select: none;
    position: absolute;
    top: 100px;
    width: 100%;
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
    &::after {
      content: '';
      position: absolute;
      top: -20px;
      left: 50%; /* 水平居中 */
      margin-left: -10px; /* 用来水平居中三角形 */
      border-width: 10px; /* 三角形的大小 */
      border-style: solid;
      border-color: transparent transparent #fff transparent; /* 上边框颜色为三角形颜色，其余透明 */
    }
    .mh-dropdown-item {
      position: relative;
      padding-left: 40px;
      text-align: left;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      font-weight: 500;
      // &:not(:last-child, .active) {
      //   &::after {
      //     content: '';
      //     position: absolute;
      //     left: 10px;
      //     right: 10px;
      //     bottom: 0;
      //     height: 1.5px;
      //     background-color: red;
      //   }
      // }
      &.active {
        color: #C6222F;
        font-size: 16px;
        font-weight: 600;
        border-radius: 8px;
        background: #FFEBEB;
        border-bottom: none;
      }
    }
  }
}
</style>