<template>
  <div class="prize-ranking-rule">
    <div class="prize-ranking-wrap">
      <div class="prize-ranking-list-header">
        <div class="list-header-col col1">RANKING</div>
        <div class="list-header-col col2">PRIZE POOL</div>
      </div>
      <div class="prize-ranking-list-body">
        <div class="list-row action-ribbon">
          <div class="list-row-col col1">1st Place</div>
          <div class="list-row-col col2">25%</div>
        </div>
        <div class="list-row">
          <div class="list-row-col col1">2nd Place</div>
          <div class="list-row-col col2">10%</div>
        </div>
        <div class="list-row">
          <div class="list-row-col col1">3rd Place</div>
          <div class="list-row-col col2">8%</div>
        </div>
        <div class="list-row">
          <div class="list-row-col col1">4th Place</div>
          <div class="list-row-col col2">5%</div>
        </div>
        <div class="list-row">
          <div class="list-row-col col1">5th Place</div>
          <div class="list-row-col col2">2%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'prize-ranking-rule'
}
</script>

<style lang="scss" scoped>
.prize-ranking-rule {
  margin: 0 auto;
  width: 850px;
  font-size: 24px;
  .prize-ranking-wrap {
    height: 100%;
    width: 820px;
    background-color: #fff;
    box-shadow: 0px 0px 24px 0px #8A8A8A1A;
    .prize-ranking-list-header {
      width: 100%;
      height: 90px;
      line-height: 90px;
      display: flex;
      .list-header-col {
        width: 50%;
        color: #000;
        &.col1 {
          margin-left: 100px;
        }
        &.col2 {
          margin-right: 100px;
          text-align: right;
        }
      }
    }
    .prize-ranking-list-body {
      .list-row {
        width: 100%;
        height: 60px;
        line-height: 60px;
        display: flex;
        &.action-ribbon {
          background-color: #FBD46B !important;
          box-shadow: 0px 4px 4px 0px #00000040;
          width: calc(100% + 20px);
          position: relative;
          left: -10px;
          &::before {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            border-top: 10px solid #FBD46B;
            border-left: 10px solid transparent;
            bottom: -10px;
            left: 0;
          }
          &::after {
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            border-top: 10px solid #FBD46B;
            border-right: 10px solid transparent;
            right: 0;
            bottom: -10px;
          }
        }
        &:nth-child(odd) {
          background-color: #F5F5F5;
        }
        .list-row-col {
          flex: 1;
          color: #000;
          &.col1 {
            margin-left: 100px;
          }
          &.col2 {
            margin-right: 100px;
            text-align: right;
          }
        }
        &.action-ribbon {
          .col1{
            margin-left: 110px;
          }
          .col2 {
            margin-right: 110px;
          }
        }
      }
    }
  }
}
</style>