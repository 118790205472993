<template>
  <MhList 
    ref="listRef"
    :type="'1'"
    :apiHttp="apiRanking" 
    :queryParams="getQueryParams"
    @finish="onFinish"
    style="margin-top: 20px;">
    <template slot-scope="{listData}">
      <div class="list__row" v-for="(item, index) of listData" :key="index">
        <div class="list__row-item col1">{{ item.score }}</div>
        <div class="list__row-item col2">
          <img class="user-avatar" src="@/assets/images/user.png">
          <div class="user-name">{{ item.nickname }}</div>
        </div>
        <div class="list__row-item col3">{{ item.value }}</div>
      </div>
    </template>
  </MhList>
</template>

<script>
import { apiRanking } from '@/api/activity'
import MhList from '@/components/mh-list/index.vue'
export default {
  name: 'ProfitList',
  components: {
    MhList,
  },
  props: {
    teamType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timer: null,
      delay: 10 * 1000
    }
  },
  watch: {
    teamType(newVal) {
      console.log('---ProfitList-teamType:', newVal)
      // 清除定时任务
      clearTimeout(this.timer)
      this.$refs.listRef.refreshList(true)
    }
  },
  methods: {
    apiRanking,
    getQueryParams() {
      return { type: '1', teamType: this.teamType }
    },
    onFinish() {
      // 清除定时任务
      clearTimeout(this.timer)
      // 重新开始任务
      this.timer = setTimeout(() => {
        console.log('---定时请求ProfitList')
        this.$refs.listRef.refreshList()
      }, this.delay)
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer) // 清除定时任务
  }
}
</script>

<style lang="scss" scoped>
.list__row {
  height: 90px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  .list__row-item {
    &.col1 {
      width: 70px;
      font-size: 16px;
    }
    &.col2 {
      flex: 1;
      display: flex;
      align-items: center;
      .user-avatar {
        width: 50px;
        height: 50px;
      }
      .user-name {
        margin-left: 20px;
        font-size: 20px;
        font-weight: 600;
      }
    }
    &.col3 {
      width: 180px;
      font-size: 20px;
      font-weight: 600;
      color: #00B43B;
    }
  }
}
</style>