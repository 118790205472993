<template>
  <div class="rules">
    <div class="rules-step">
      <div class="rules-1">
        <div class="rules-1-left">
          <img src="@/assets/images/rule1-1.png" width="480" height="480">
        </div>
        <div class="rules-1-right">
          <div class="rules-1-title">
            <!-- @/assets/images/rule1.png -->
            <img :src="getImageByName('rule1')">
            <div class="rules-title-text">
              <div class="rules-t1">Contest Registration</div>
              <div class="rules-t2">Monday to Friday</div>
              <img class="uline" :src="getImageByName('underline')">
            </div>
          </div>
          <div class="rules-1-desc">
            <div class="client">
              <img class="avatar" src="@/assets/images/male.png">
              <div class="client-t">New Clients</div>
              <div class="client-desc">Sign up and deposit ${{getDepositAmount}} (±10% variation accepted). Click 'Join Now' for automatic approval if funds are correct and there are no current investments. Trading tracking will begin upon approval.</div>
              <!-- <div class="client-desc">Sign up, deposit ${{getDepositAmount}} (±10% variation accepted). Click '{{ getExampleDesc3 }}'; automatic approval if funds are correct and no current investments. Trading tracking begins on approval.</div> -->
            </div>
            <div class="client exist">
              <img class="avatar" src="@/assets/images/female.png">
              <div class="client-t">Existing Clients</div>
              <div class="client-desc">Keep a ${{getDepositAmount}} balance (±10% flexibility). Click 'Join Now' for auto-approval and to initiate trading data monitoring.</div>
              <!-- <div class="client-desc">Keep a ${{getDepositAmount}} balance (±10% flexibility). Click '{{ getExampleDesc3 }}' for auto-approval and to initiate trading data monitoring.</div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="rules-2">
        <div class="rules-2-left">
          <div class="rules-2-title">
            <img :src="getImageByName('rule2')">
            <div class="rules2-title-text">
              <div class="rules-t1">Trading Participation</div>
              <div class="rules-t2">Monday to Friday</div>
              <img class="uline" :src="getImageByName('underline')">
            </div>
          </div>
          <div class="rules-2-desc">
            <p>Before the contest begins, ensure your account is funded with ${{getDepositAmount}} trading margin. For fairness, withdrawals are allowed, but no additional deposits are permitted throughout each round of the contest.</p>
            <p>Participants may access leverage of up to 1000x. The system automatically ranks participants in real-time, based on trading profits and trade volume. </p>
            <p>The trading window is open from the time of system approval until the markets’ close on Friday. </p>
          </div>
        </div>
        <div class="rules-2-right">
          <img src="@/assets/images/rule2-2.png">
        </div>
      </div>
      <div class="rules-3">
        <div class="rules-3-left">
          <img src="@/assets/images/rule3-3.png">
        </div>
        <div class="rules-3-right">
          <div class="rules-3-title">
            <img :src="getImageByName('rule3')">
            <div class="rules3-title-text">
              <div class="rules-t1">Automatic Prize Pool Sharing</div>
              <div class="rules-t2">Saturday and Sunday</div>
              <img class="uline" :src="getImageByName('underline')">
            </div>
          </div>
          <div class="rules-3-desc">
            <p>For every lot you trade, MHMarkets will contribute $1 to the Prize Pool with no limit. </p>
            <p class="p2">
              The top 5 participants in either the trading profit or trading volume leaderboards will share the<br/>
              entire prize pool at the rate of: 25% - 10% - 8% - 5% - 2%.<br/>
              <span class="xt">(subject to adjustment according to the actual number of participants) </span>
            </p>
            <p>Prizes will be distributed automatically by the end of week, with winnings directly credited to the participants' PRIME accounts. </p>
          </div>
        </div>
      </div>
    </div>
    <div class="rules-share">
      <div class="rules-share__wrap rule-desc">
        <div class="rules-share__title">Rules: </div>
        <ul class="rules-share__text">
          <li>This event is applicable to Prime accounts, with each participant allowed to contribute a maximum of $1000 per round ($50 for DEMO). Withdrawals are permitted at any time, but additional deposits during each round are prohibited to ensure fairness.</li>
          <li>This event is designed for direct clients only; any form of IBs/partners may not participate.</li>
          <li>MH Markets strictly prohibits any form of malicious cheating. If a participant engages in behavior that disrupts the normal operation of the event (including, but not limited to, scalping, hedging, latency arbitrage, spoofing, etc.), they will be immediately disqualified and, in severe cases, their account may be frozen.</li>
          <li>MH Markets reserves the right to amend, suspend, or terminate this event and its terms, and holds the final interpretative authority of the event. For any inquiries, please contact the customer service center at support@mhmarkets.com.</li>
        </ul>
      </div>
      <div class="dividing-line"></div>
      <div class="rules-share__wrap prize-share">
        <div class="rules-share__title">Prize Sharing: </div>
        <ul class="rules-share__text">
          <li>
            <p>Winning rule: The top 5 traders on the "Trading Profit Leaderboard" will share 50% of the total prize pool, and the top 5 on the "Trading Volume Leaderboard" will share the remaining 50% following this allocation:</p>
            <PrizeRankingRule />
          </li>
          <li>Awarding time: The campaign runs weekly, with registration open from Monday to Friday. Winners are announced after the market closes on Friday, and prizes are distributed on Saturday and Sunday.</li>
        </ul>
        <div class="rules-share__btn">
          <div class="share-friend__btn-wrap" @click="handleShare">
            <div class="btn-text">Share</div>
            <svg-icon class-name="share-icon" icon-class="share"/>
          </div>
          <div class="rules-share__btn-wrap tele" @click="handleTele">
            <div class="btn-text">Follow Us on Facebook</div>
            <img class="btn-icon" src="@/assets/images/facebook.png">
          </div>
          <div class="rules-share__btn-wrap whats" @click="handleWhats">
            <div class="btn-text">Follow Us on Instagram</div>
            <img class="btn-icon" src="@/assets/images/instagram.png">
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="rules-example">
      <div class="rules-example__title">Participation Example</div>
      <div class="rules-example__content">
        <div :class="['rules-example__content-item','col1', {'account-demo': isDemo}]">
          <div class="col_text">Register PRIME Account<br/> on Tuesday</div>
        </div>
        <div :class="['rules-example__content-item','col2', {'account-demo': isDemo}]">
          <div class="col_text">Account Verified,<br/>Deposit ${{getDepositAmount}}</div>
        </div>
        <div :class="['rules-example__content-item','col3', {'account-demo': isDemo}]">
          <div class="col_text">Clicks “{{ getExampleDesc3 }}”</div>
        </div>
        <div :class="['rules-example__content-item','col4', {'account-demo': isDemo}]">
          <div class="col_text">Trading Data Begins<br/> Calculation</div>
        </div>
        <div :class="['rules-example__content-item','col5', {'account-demo': isDemo}]">
          <div class="col_text">Trades from Tuesday to Friday</div>
        </div>
        <div :class="['rules-example__content-item','col6', {'account-demo': isDemo}]">
          <div class="col_text">Ranked 5th in Trading<br/> Profit Leaderboard & 3rd in<br/> the Trading Volume<br/> Leaderboard.</div>
        </div>
        <div :class="['rules-example__content-item','col7', {'account-demo': isDemo}]">
          <div class="col_text">Receives $ X-amount from profit leaderboard and $ Y-amount from Volume Leaderboard amounting to $ Z-amount</div>
        </div>
        <div :class="['rules-example__content-item','col8', {'account-demo': isDemo}]">
          <div class="col_text">Prize money deposited<br/> into PRIME Account on Sunday</div>
        </div>
      </div>
    </div>
    -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PrizeRankingRule from './PrizeRankingRule.vue'
// import { shareToWhatsApp } from '@/utils/index'
export default {
  name: 'Rules',
  components: {
    PrizeRankingRule
  },
  computed: {
    ...mapGetters(['accountType']),
    isDemo() {
      return this.accountType === '2'
    },
    getImageByName() {
      return (name) => {
        let imgName = name
        if (this.isDemo) {
          imgName += '-demo'
        }
        return require(`@/assets/images/${imgName}.png`)
      }
    },
    // 入金金额
    getDepositAmount() {
      if (this.isDemo) {
        return '50'
      }
      return '1,000'
    },
    getExampleDesc3() {
      if (this.isDemo) {
        return 'Entry Forex Trading Contest'
      }
      return 'Premium Forex Trading Contest'
    }
  },
  methods: {
    handleShare() {
      this.$bus.$emit('showInvitePop')
    },
    handleTele() {
      // 关注Facebook
      window.open('https://bit.ly/3Mx6cOi')
    },
    handleWhats() {
      // 关注Instagram
      window.open('https://bit.ly/49rf3Le')
    }
  },
}
</script>

<style lang="scss" scoped>
.rules {
  text-align: left;
  .rules-step {
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .rules-1 {
      display: flex;
      align-items: end;
      .rules-1-right {
        .rules-1-title {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 92px;
            height: 200px;
          }
          .rules-title-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            .rules-t1 {
              font-weight: 600;
              line-height: 50px;
              font-size: 48px;
              color: #1E174F;
            }
            .rules-t2 {
              font-weight: 500;
              margin-top: 10px;
              line-height: 18px;
              font-size: 18px;
              color: #1E174F;
            }
            .uline {
              margin-top: 10px;
              width: 180px;
              height: 20px;
            }
          }
        }
        .rules-1-desc {
          display: flex;
          .client{
            width: 400px;
            height: 340px;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
          }
          .client {
            padding-left: 40px;
            padding-right: 40px;
            text-align: center;
            .avatar {
              margin-top: -60px;
              border-radius: 50%;
              width: 160px;
              height: 160px;
            }
            .client-t {
              margin-top: 30px;
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
              color: var(--theme-color);
            }
            .client-desc {
              margin-top: 10px;
              font-weight: 500;
              font-size: 16px;
            }
          }
          .exist{
            margin-left: 30px;
          }
        }
      }
    }
    .rules-2 {
      margin-top: 80px;
      display: flex;
      .rules-2-left{
        .rules-2-title {
          display: flex;
          align-items: center;
          img {
            width: 140px;
            height: 200px;
          }
          .rules2-title-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            .rules-t1 {
              line-height: 52px;
              font-weight: 600;
              font-size: 48px;
              color: #1E174F;
            }
            .rules-t2 {
              font-weight: 500;
              margin-top: 10px;
              line-height: 18px;
              font-size: 18px;
              color: #1E174F;
            }
            .uline {
              margin-top: 10px;
              width: 180px;
              height: 20px;
            }
          }
        }
        .rules-2-desc {
          p {
            max-width: 800px;
            padding: 20px 40px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            font-weight: 500;
            font-size: 16px;
            color: #1E174F;
          }
        }
      }
      .rules-2-right {
        img {
          width: 480px;
          height: 480px;
        }
      }
    }
    .rules-3 {
      margin-top: 80px;
      display: flex;
      align-items: end;
      .rules-3-left {
        img {
          width: 480px;
          height: 480px;
        }
      }
      .rules-3-right {
        .rules-3-title {
          margin-left: -75px;
          display: flex;
          align-items: center;
          img {
            width: 138px;
            height: 200px;
          }
          .rules3-title-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            .rules-t1 {
              line-height: 52px;
              font-weight: 600;
              font-size: 48px;
              color: #1E174F;
            }
            .rules-t2 {
              margin-top: 10px;
              font-weight: 500;
              line-height: 18px;
              font-size: 18px;
              color: #1E174F;
            }
            .uline {
              margin-top: 10px;
              width: 180px;
              height: 20px;
            }
          }         
        }
        .rules-3-desc {
          p {
            max-width: 800px;
            padding: 20px 40px;
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            font-weight: 500;
            font-size: 16px;
            color: #1E174F;
          }
          .p2 {
            .xt {
              font-style: italic;
            }
          }
        }
      }
    }
  }
  .rules-share {
    background: linear-gradient(270deg, #BF204F 0%, #51164E 100%);
    padding: 50px 150px;
    .rules-share__wrap {
      .rules-share__title {
        font-size: 32px;
        font-weight: 600;
        color: #fff;
        line-height: 40px;
      }
      .rules-share__text {
        margin-top: 20px;
        margin-left: 30px;
        li {
          list-style: decimal;
          font-size: 20px;
          font-weight: 500;
          color: #fff;
          padding-bottom: 20px;
        }
      }
      .rules-share__btn {
        user-select: none;
        margin-top: 30px;
        display: flex;
        justify-content: center;
        .share-friend__btn-wrap {
          cursor: pointer;
          width: 130px;
          height: 60px;
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.3);
          box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: rgba(255, 255, 255, 0.4);
          }
          .btn-text {
            line-height: 24px;
            font-size: 20px;
            color: #fff;
          }
          .share-icon {
            font-size: 24px;
            color: #fff;
          }
        }
        .rules-share__btn-wrap {
          cursor: pointer;
          margin-left: 20px;
          height: 60px;
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.3);
          box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.25);
          padding: 0 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: rgba(255, 255, 255, 0.4);
          }
          .btn-icon {
            margin-left: 10px;
            width: 40px;
            height: 40px;
          }
          .btn-text {
            font-size: 20px;
            font-weight: 500;
            color: #fff;
          }
        }
      }
      &.prize-share {
        li {
          list-style: disc;
        }
      }
    }
    .dividing-line {
      height: 1px;
      background-color: #fff;
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }
  .rules-example {
    margin-top: 80px;
    .rules-example__title {
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      color: #1E174F;
      text-align: center
    }
    .rules-example__content {
      // padding-left: 20px;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .rules-example__content-item {
        width: 350px;
        height: 350px;
        padding: 145px 45px 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.col1 {
          background-image: url('~@/assets/images/exa_01.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_01-demo.png');
          }
        }
        &.col2 {
          background-image: url('~@/assets/images/exa_02.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_02-demo.png');
          }
        }
        &.col3 {
          background-image: url('~@/assets/images/exa_03.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_03-demo.png');
          }
        }
        &.col4 {
          background-image: url('~@/assets/images/exa_04.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_04-demo.png');
          }
        }
        &.col5 {
          background-image: url('~@/assets/images/exa_05.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_05-demo.png');
          }
        }
        &.col6 {
          background-image: url('~@/assets/images/exa_06.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_06-demo.png');
          }
        }
        &.col7 {
          background-image: url('~@/assets/images/exa_07.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_07-demo.png');
          }
        }
        &.col8 {
          background-image: url('~@/assets/images/exa_08.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_08-demo.png');
          }
        }
        .col_text {
          // padding: 10px 20px;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }
}
</style>