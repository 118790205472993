<template>
  <div class="mh-content">
    <ContentNav @change="onChange" />
    <div class="mh-content-tabs">
      <Info class="main-fluid" v-if="selected === 1" />
      <Rules class="mh-container" v-if="selected === 2" />
      <CurrentRound class="main-fluid" v-if="selected === 3" />
      <Archive class="main-fluid" v-if="selected === 4" />
      <About class="main-fluid" v-if="selected === 5" />
    </div>
    <div class="mh-content-footer" v-if="showJoinBtn">
      <div class="wen">Ready to Win Prizes?</div>
      <div class="mh-btn btn-join" @click="handleJoin">Join Now</div>
    </div>
  </div>
</template>

<script>
import ContentNav from './ContentNav.vue'
import Info from './Info/index.vue'
import Rules from './Rules/index.vue'
import CurrentRound from './current-round/index.vue'
import Archive from './archive/index.vue'
import About from './About/index.vue'

export default {
  name: 'MhContent',
  components: {
    ContentNav,
    Info,
    Rules,
    CurrentRound,
    Archive,
    About
  },
  data() {
    return {
      selected: 1
    }
  },
  computed: {
    showJoinBtn() {
      return [1, 3 ,4].includes(this.selected)
    }
  },
  methods: {
    onChange(val) {
      this.selected = val
    },
    handleJoin() {
      this.$bus.$emit('showLoginPop', {type: '1'})
    }
  },
}
</script>

<style lang="scss" scoped>
.mh-content {
  position: relative;
  // .mh-content-tabs {
  //   width: 1440px;
  //   margin: 0 auto;
  // }
  .mh-content-footer {
    height: 120px;
    background: #FCF2E3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    .wen {
      font-size: 40px;
      font-weight: 500;
    }
    .btn-join {
      margin-left: 100px;
      user-select: none;
      // margin: 0 auto;
      width: 200px;
      max-width: 300px;
      height: 48px;
      line-height: 48px;
      border-radius: 10px;
      background-color: var(--theme-color);
      color: #fff;
      &:hover {
        background-color: var(--theme-hover-color);
      }
    }
  }
}
</style>