/**
 * 分享到社交应用
 * @param { platform } 分享平台
 * @param { shareUrl } 分享Url
 */
export function shareToApps(platform, shareUrl) {
  let url = ''
  switch (platform) {
    case 'whats':
      url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`
      break;
    case 'tele':
      url = `https://telegram.me/share/url?url=${encodeURIComponent(shareUrl)}`
      break;
    case 'twitter':
      url = `https://twitter.com/intent/post?url=${encodeURIComponent(shareUrl)}&via=YouTube&related=YouTube,YouTubeTrends,YTCreators`
    default:
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&title=&description=&caption=locale=en_US`
      break;
  }
  window.open(url)
}