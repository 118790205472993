<template>
  <div class="info">
    <PrizePool />
    <div class="info-view">
      <JoinExplain />
      <!-- <Ranking /> -->
      <ParticipationExample />
    </div>
  </div>
</template>

<script>
import PrizePool from '@/components/PrizePool/index.vue'
import JoinExplain from './join-explain.vue'
import Ranking from './ranking.vue'
import ParticipationExample from '@/components/ParticipationExample/index.vue'
export default {
  name: 'Info',
  components: {
    PrizePool,
    JoinExplain,
    // Ranking,
    ParticipationExample
  }
}
</script>

<style lang="scss" scoped>
.info {
  .info-view {
    margin: 0 auto;
    width: 1380px;
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
  }
}
</style>