<template>
  <header>
    <!-- pc -->
    <div class="mh-header fixed-top" :class="{'scroll-theme': showScollTheme}">
      <div class="mh-header-wrap container-fluid mh-flex-between">
        <div class="nav-brand"><img src="~@/assets/logo.png" height="36"></div>
        <div class="account-switch">
          <div :class="['account-type', {'active': accountType === '1'}]" @click="handleSwitch('1')">Real Account</div>
          <div :class="['account-type', {'active': accountType === '2'}]" @click="handleSwitch('2')">Demo Account</div>
        </div>
        <ul class="mh-navbar mh-flex-between">
          <li class="nav-item">
            <div class="mh-btn btn-login" @click="handleLogin">Login</div>
            <!-- <el-button class="btn-login" type="primary" plain round @click="handleLogin">Log in</el-button> -->
          </li>
          <li class="nav-item">
            <div class="mh-btn btn-open" @click="handleSign">Open Account</div>
            <!-- <el-button class="btn-open" type="primary" round>Open Account</el-button> -->
          </li>
        </ul>
      </div>
    </div>
    <!-- mobile -->
    <MHeader />
  </header>
</template>

<script>
import { debounce } from '@/utils/index'
import MHeader from './MHeader.vue'

import { mapState, mapMutations } from 'vuex'

export default {
  name: 'MhHeader',
  components: {
    MHeader,
  },
  data() {
    return {
      showScollTheme: false
    }
  },
  computed: {
    ...mapState('app', ['accountType'])
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapMutations('app', ['SET_ACCOUNTTYPE']),
    init() {
      this.checkWindowScroll()
      window.addEventListener("scroll", debounce(this.checkWindowScroll, 100), !1)
    },
    checkWindowScroll() {
      var t = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      this.showScollTheme = t > 30
    },
    handleLogin() {
      this.$bus.$emit('showLoginPop', {type: '1'})
    },
    handleSign() {
      this.$bus.$emit('showLoginPop', {type: '2'})
    },
    handleSwitch(type) {
      if(this.accountType === type) return
      this.SET_ACCOUNTTYPE(type)
      if(this.accountType === '1') {
        document.documentElement.style.setProperty("--theme-color", '#C6222F')
        document.documentElement.style.setProperty("--theme-select-bg", '#FFD8D8')
        document.documentElement.style.setProperty("--theme-gradient-to", '#9A191B')
        document.documentElement.style.setProperty("--theme-gradient-form", '#4B1D1F')
        document.documentElement.style.setProperty("--theme-minor-color", '#BF204F')
        document.documentElement.style.setProperty("--minor-color", '#D81517')
        document.documentElement.style.setProperty("--theme-hover-color", '#DA2534')
        document.documentElement.style.setProperty("--theme-rev-color", '#142E8A')
        document.documentElement.style.setProperty("--theme-color2", '#9A191B')
        // 
      } else {
        document.documentElement.style.setProperty("--theme-color", '#142E8A')
        document.documentElement.style.setProperty("--theme-select-bg", '#C6D2FD')
        document.documentElement.style.setProperty("--theme-gradient-to", '#142E8A')
        document.documentElement.style.setProperty("--theme-gradient-form", '#081648')
        document.documentElement.style.setProperty("--theme-minor-color", '#142E8A')
        document.documentElement.style.setProperty("--minor-color", '#152E89')
        document.documentElement.style.setProperty("--theme-hover-color", '#1735A0')
        document.documentElement.style.setProperty("--theme-rev-color", '#C6222F')
        document.documentElement.style.setProperty("--theme-color2", '#142E8A')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mh-header-wrap {
  height: 80px;
  width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  .account-switch {
    height: 60px;
    padding: 0 12px;
    background-color: #D9D9D9;
    border-radius: 50px;
    display: flex;
    align-items: center;
    user-select: none;
    .account-type {
      cursor: pointer;
      padding: 12px 24px;
      line-height: 20px;
      font-size: 16px;
      border-radius: 50px;
      &:hover {
        color: var(--theme-rev-color);
      }
      &.active {
        background: var(--theme-color2);
        color: #fff;
        font-weight: 500;
        // border-bottom: 2px solid var(--theme-color);
      }
    }
  }
  .mh-navbar {
    user-select: none;
    .nav-item {
      .btn-login {
        width: 120px;
        cursor: pointer;
        height: 38px;
        width: 120px;
        border: 1px solid var(--theme-color);
        border-radius: 20px;
        line-height: 38px;
        font-size: 14px;
        color: var(--theme-color);
        font-weight: 500;
        &:hover {
          border: 1px solid var(--theme-hover-color);
          color: var(--theme-hover-color);
          background-color: #F2F2F2;
        }
      }
      .btn-open {
        margin-left: 20px;
        cursor: pointer;
        margin-left: 20px;
        height: 38px;
        width: 144px;
        background-color: var(--theme-color);
        border-radius: 20px;
        line-height: 38px;
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        &:hover {
          background-color: var(--theme-hover-color);
        }
      }
    }
  }
}
</style>