import Vue from 'vue'
import Router from 'vue-router'

import Activity from '@/views/activity/index.vue'

Vue.use(Router)

const routes = [
  {
    path: '/', 
    redirect: '/activity'
  },
  {
    path: '/activity',
    component: Activity
  }
]

const createRouter = () => new Router({
  // mode: 'history',
  routes
})

const router = createRouter()

export default router
