<template>
  <div class="ranking">
    <div class="team-tab">
      <div :class="['team-tab__nav', {'team-action': selectedTeam === '1'}]" @click="handleTeamChange('1')">
        <div class="nav-text">Premium Level Team</div>
      </div>
      <div :class="['team-tab__nav', {'team-action': selectedTeam === '2'}]" @click="handleTeamChange('2')">
        <div class="nav-text">Entry Level Team</div>
      </div>
    </div>
    <div class="ranking-wrap">
      <div class="ranking-tab">
        <div :class="['ranking-tab-nav', {'selected': selected === '1'}]" @click="handleNavClick('1')">
          <div class="nav-text">Trading Profit Leaderboard</div>
          <div class="nav-active-bar"></div>
        </div>
        <div :class="['ranking-tab-nav', {'selected': selected === '2'}]" @click="handleNavClick('2')">
          <div class="nav-text">Trading Volume Leaderboard</div>
          <div class="nav-active-bar"></div>
        </div>
      </div>
      <div class="ranking-header">
        <div class="ranking-header-title">Current Round Leaders</div>
        <div class="update-time"><span>Ends on: </span>{{ endtimeText }}</div>
      </div>
      <ProfitList :teamType="selectedTeam" v-if="selectedTeam === '1' && selected === '1'" />
      <ProfitList :teamType="selectedTeam" v-else-if="selectedTeam === '2' && selected === '1'" />
      <VolumeList :teamType="selectedTeam" v-else-if="selectedTeam === '1' && selected === '2'" />
      <VolumeList :teamType="selectedTeam" v-else-if="selectedTeam === '2' && selected === '2'" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { apiRanking } from '@/api/activity'
import { getGMT3Time } from '@/utils/index'

import ProfitList from './ProfitList.vue'
import VolumeList from './VolumeList.vue'
export default {
  components: {
    ProfitList,
    VolumeList
  },
  data() {
    return {
      selectedTeam: '1',
      selected: "1"
    }
  },
  computed: {
    ...mapState({
      endtime: state => state.realtime.endtime
    }),
    endtimeText() {
      if (this.endtime) {
        return getGMT3Time(this.endtime)
      } else {
        return '--'
      }
    }    
  },
  methods: {
    apiRanking,
    handleTeamChange(val) {
      this.selectedTeam = val
    },
    handleNavClick(val) {
      this.selected = val
    }
  },
}
</script>

<style lang="scss" scoped>
.ranking {
  width: 650px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  .team-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    .team-tab__nav {
      cursor: pointer;
      width: 50%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.team-action {
        background-color: var(--theme-color);
        border-radius: 16px 16px 0 0;
        .nav-text {
          color: #fff;
          font-weight: 700;
        }
      }
      .nav-text {
        color: #000;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }
  .ranking-wrap {
    border-top: 2px solid var(--theme-color);
    padding: 30px 50px;
    width: 100%;
    .ranking-tab {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .ranking-tab-nav {
        user-select: none;
        padding-left: 4px;
        padding-right: 4px;
        font-size: 20px;
        line-height: 24px;
        color: #000;
        &.selected{
          color: var(--theme-color);
          font-weight: 600;
          .nav-active-bar {
            background-color: var(--theme-color);
          }
        }
        &:hover {
          color: var(--theme-color);
        }
        .nav-active-bar {
          margin-top: 10px;
          height: 2px;
        }
      }
    }
    .ranking-header {
      text-align: left;
      margin-top: 30px;
      .ranking-header-title {
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
      }
      .update-time {
        margin-top: 10px;
        font-size: 16px;
        line-height: 20px;
        color: var(--theme-color);
      }
    }

    .list__row {
      height: 90px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      .list__row-item {
        &.col1 {
          width: 70px;
          font-size: 16px;
        }
        &.col2 {
          flex: 1;
          display: flex;
          align-items: center;
          .user-avatar {
            width: 50px;
            height: 50px;
          }
          .user-name {
            margin-left: 20px;
            font-size: 20px;
            font-weight: 600;
          }
        }
        &.col3 {
          width: 180px;
          font-size: 20px;
          font-weight: 600;
          color: #00B43B;
        }
      }
    }
  }
}
</style>