<template>
<div class="prize-pool">
  <div class="prize-pool-container">
    <div class="prize-pool-wrap">
      <div class="view">
        <div class="view-title">Current Round</div>
        <div class="view-content">
          <div class="view-item">
            <div class="num">{{ day }}</div>
            <div class="unit">DAYS</div>
          </div>
          <div class="view-item">
            <div class="num">{{ hour }}</div>
            <div class="unit">HOURS</div>
          </div>
          <div class="view-item">
            <div class="num">{{ minute }}</div>
            <div class="unit">MINUTES</div>
          </div>
          <div class="view-item">
            <div class="num">{{ second }}</div>
            <div class="unit">SECONDS</div>
          </div>
        </div>
        <div class="view-end"><span>Ends on: </span>{{ endtimeText }}</div>
      </div>
      <div class="view">
        <div class="view-title pool">
          <div class="mh-btn share-btn-wrap" @click="handleShare">
            <div class="btn-text">Share</div>
            <img class="btn-icon" src="@/assets/images/share2.png">
          </div>
          <div style="margin-left:20px">Prize Pool</div>
        </div>
        <div class="view-content">
          <div class="view-item">
            <div class="num">$<VueCountUp :start-val="lastPrizeAmount" :end-val="activity.prizeAmount" /></div>
            <div class="unit">PRIZE POOL</div>
          </div>
          <div class="view-item">
            <div class="num">
              <VueCountUp :start-val="lastMembersTotal" :end-val="activity.membersTotal" />
            </div>
            <div class="unit">PARTICIPANTS</div>
          </div>
        </div>
        <div class="view-end">For every lot you trade, MH Markets will contribute $1 to the Prize Pool with no limit.</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import VueCountUp from 'vue-countup-v2'

import { getGMT3Time } from '@/utils/index'

export default {
  name: 'PrizePool',
  components: {
    VueCountUp,
  },
  data() {
    return {
      timer: null,
      lastPrizeAmount: 30000, // 上次奖金金额
      lastMembersTotal: 100, // 上次参与人数
      day: '--',
      hour: '--',
      minute: '--',
      second: '--'
    }
  },
  computed: {
    ...mapState({
      activity: state => state.realtime.activity,
      endtime: state => state.realtime.endtime
    }),
    endtimeText() {
      if (this.endtime) {
        return getGMT3Time(this.endtime)
      } else {
        return '--'
      }
    },
    // showPrizeAmount() {
    //   if(this.activity.prizeAmount) {
    //     return this.activity.prizeAmount.toFixed(2)
    //   }
    // }
  },
  watch: {
    activity(newVal) {
      this.lastPrizeAmount = newVal.prizeAmount
      this.lastMembersTotal = newVal.membersTotal
    },
    endtime(newVal) {
      console.log('--watch--endtime:', this.endtime)
      this.endtime && this.countdownTask()
    }
  },
  mounted() {
    console.log('--mounted--endtime:', this.endtime)
    this.endtime && this.countdownTask()
  },
  methods: {
    // 倒计时任务
    countdownTask() {
      let currDate = new Date()
      let endtime = getGMT3Time(this.endtime).toDate() //new Date(this.endtime)
      let timeDiff = endtime.getTime() - currDate.getTime()
      if (timeDiff >= 0) {
        const day = Math.floor((timeDiff / 1000 / 60 / 60 / 24))
        const hour = Math.floor((timeDiff / 1000 / 60 / 60) % 24)
        const minute = Math.floor((timeDiff / 1000 / 60) % 60)
        const second = Math.floor((timeDiff / 1000) % 60)

        this.day = this.fillTime(day)
        this.hour = this.fillTime(hour)
        this.minute = this.fillTime(minute)
        this.second = this.fillTime(second)

        this.timer = setTimeout(this.countdownTask, 1000)
      } else {
        clearTimeout(this.timer)
      }
    },
    // 是否需要补0
    fillTime(i) {
      if ( i < 10) {
        i = '0' + i
      }
      return i
    },
    handleShare() {
      this.$bus.$emit('showPosterPop')
    }
  }
}
</script>

<style lang="scss" scoped>
.prize-pool {
  position: relative;
  height: 380px;
  background: linear-gradient(92.83deg, var(--theme-gradient-to) 0%, var(--theme-gradient-form) 100%);
  &::after {
    content: '';
    position: absolute;
    top: 90px;
    left: 50%;
    width: 1px;
    height: 240px;
    background-color: #fff;
  }
  .prize-pool-container {
    width: 1440px;
    margin: 0 auto;
    position: relative;
  }
  .share-btn-wrap {
    // position: absolute;
    // top: 24px;
    // right: 20px;
    width: 100px;
    height: 44px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 0px 10px 1px #00000040;
    border-radius: 10px;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba(255, 255, 255, 0.8);
    }
    .btn-text {
      font-size: 20px;
      line-height: 20px;
      color: #1E174F;
    }
    .btn-icon {
      margin-left: 10px;
      width: 18px;
      height: 20px;
    }
  }
  .prize-pool-wrap {
    padding-top: 90px;
    margin: 0 auto;
    width: 1180px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .view {
    width: 460px;
    height: 250px;
    .view-title {
      font-size: 40px;
      font-weight: 600;
      color: #fff;
      line-height: 48px;
      // text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .view-content {
      margin-top: 20px;
      height: 110px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(255, 255, 255, 0.8);
      .view-item {
        .num {
          line-height: 76px;
          font-size: 64px;
          font-weight: 600;
          color: #fff;
        }
        .unit {
          line-height: 24px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
    .view-end {
      margin-top: 10px;
      text-align: left;
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>