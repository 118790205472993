import axios from "axios"
import { Message } from 'element-ui'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// request拦截器
service.interceptors.request.use(config => {
  console.log(config)
  return config
})
// response拦截器
service.interceptors.response.use(res => {
  const code = res.data.code
  if (code !== 200) {
    Message({
      message: res.data.message || '系统异常',
      type: 'error',
      duration: 5 * 1000
    })
  }
  return res.data
},
error => {
  console.log('Server Err:', error)
})

export default service