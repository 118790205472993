<template>
  <div class="login-panel">
    <el-form ref="loginFormRef" :model="loginForm" :rules="loginRules">
      <el-form-item label="Email" prop="username">
        <el-input v-model="loginForm.username" ref="usernameRef"></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input type="password" v-model="loginForm.password" ref="passwordRef" autocomplete="off"></el-input>
      </el-form-item>
      <div class="rememberMe">
        <el-checkbox v-model="loginForm.rememberMe" :true-label="1" :false-label="0">Remember Me</el-checkbox>
        <div class="forget">Forget Password?</div>
      </div>
      <el-form-item class="btn-wrap">
        <el-button type="primary" class="btn-login" @click="submitForm">Log in</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { goToUserBackend } from '@/utils/index'

export default {
  name: 'login-panel',
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        rememberMe: false
      },
      loginRules: {
        username: [{
          required: true, message: 'Please enter Email', trigger: 'blur'
        }, {
          type: 'email', message: 'please enter your vaild email', trigger: 'blur'
        }],
        password: [{
          required: true, message: 'Please enter Password', trigger: 'blur'
        }, {
          min: 8, max: 20, message: 'Password length is between 8-20', trigger: 'blur'
        }, {
          trigger: 'blur', validator: (rule, value, callback) => {
            // 必须包含字母和数字
            const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/
            if (!regex.test(value)) {
              callback(new Error('Password must contain letters and numbers'))
            } else {
              callback()
            }
          }
        }]
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.loginFormRef.validate((valid) => {
        if (valid) {
          this.$store.dispatch('UserLogin', this.loginForm).then((res) => {
            if (res.code === 200) {
              const { token } = res.data
              goToUserBackend(token) // 跳转到用户中心
            }
          })
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.login-panel {
  padding-top: 30px;
  .el-form {
    .rememberMe {
      display: flex;
      justify-content: space-between;
      align-items: center;
      ::v-deep .el-form-item__content {
        line-height: 20px;
      }
      .el-checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        ::v-deep .el-checkbox__label {
          color: #000;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .forget {
        font-size: 16px;
        font-weight: 600;
        color:#C6222F;
      }
    }
    .btn-wrap {
      text-align: center;
      margin-top: 50px;
      .btn-login {
        width: 300px;
        height: 50px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .mh-intro {
    margin-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    color: #fff;
    font-size: 12px;
  }
}
</style>