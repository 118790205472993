<template>
  <div class="content-nav">
    <ul class="nav-list ab-h-center flex-around flex-align-center" @click="handleNav">
      <li :class="['nav-item', {'active': selected === 1}]" data-index="1">INFORMATION</li>
      <li :class="['nav-item', {'active': selected === 2}]" data-index="2">RULES</li>
      <li :class="['nav-item', {'active': selected === 3}]" data-index="3">LEADERBOARD</li>
      <li :class="['nav-item', {'active': selected === 4}]" data-index="4">HISTORY</li>
      <li :class="['nav-item', {'active': selected === 5}]" data-index="5">ABOUT US</li>
    </ul>
    <div class="m-nav-wrap ab-h-center">
      <MhDropdown :selected.sync="selected" />
    </div>
  </div>
</template>

<script>
import MhDropdown from './MhDropdown.vue'
export default {
  name: 'content-nav',
  components: {
    MhDropdown,
  },
  data() {
    return {
      selected: 1
    }
  },
  watch: {
    selected(newVal) {
      this.$emit('change', newVal)
    }
  },
  mounted() {
    // 订阅事件
    this.$bus.$on('to-rules', () => {
      this.selected = 2
      this.$el.scrollIntoView({
        behavior: 'smooth',
        block: "start"
      })
    })
  },
  methods: {
    handleNav(event) {
      const target = event.target
      if (target.tagName === 'UL') return
      const index = Number(target.dataset.index)
      if (this.selected !== index) {
        this.selected = index
      }
    }
  }  
}
</script>

<style lang="scss" scoped>
.content-nav {
  scroll-margin-top: 150px;
  .nav-list {
    user-select: none;
    z-index: 1000;
    background-color: #fff;
    top: -50px;
    width: 988px;
    border-radius: 16px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    padding-left: 50px;
    padding-right: 50px;
    .nav-item {
      cursor: pointer;
      margin: 15px 0;
      padding: 15px 36px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      border-radius: 12px;
      &:hover {
        font-weight: 600;
        color: var(--theme-color);
      }
      &.active {
        font-weight: 600;
        color: var(--theme-color);
        background-color: var(--theme-select-bg);
      }
    }    
  }
  .m-nav-wrap {
    display: none;
    top: -40px;
    width: 100%;
    z-index: 1000;
  }
}
</style>