import request from '@/utils/request'

// 初始化数据
export function apiInitData(data) {
  return request({
    url: '/api/system/landing-init',
    method: 'get',
    params: data
  })
}

// 当前排行榜
export function apiRanking(data) {
  return request({
    url: '/api/activity/ranking-list',
    method: 'get',
    params: data
  })
}

// 排行榜历史
export function apiArchiveList(data) {
  return request({
    url: '/api/activity/archive-list',
    method: 'get',
    params: data
  })
}