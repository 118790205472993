<template>
  <div :class="['mh-banner','main-fluid', {'account-demo': isDemo}]">
    <div class="mh-banner-wrap mh-container">
      <div class="banner-main" v-if="!this.isDemo">
        <h1 class="main-title">MH MARKETS TRADING CONTEST</h1>
        <h2 class="sub-title">Win Big Prizes Every Week!</h2>
        <div class="btn-wrap">
          <div class="btn-join" @click="handleJoin">Join Now</div>
          <div class="btn-invite" @click="handleInvite">Invite to Win</div>
        </div>
      </div>
      <div class="banner-main" v-else>
        <h1 class="main-title">MH MARKETS DEMO TRADING CONTEST</h1>
        <h2 class="sub-title">
          Compete with a demo account to <br/>win cash prizes weekly.
        </h2>
        <div class="btn-wrap">
          <div class="btn-join" @click="handleJoin">Join Now</div>
          <div class="btn-invite demo" @click="handleInvite">Invite Friends</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MhBanner',
  computed: {
    ...mapGetters(['accountType']),
    isDemo() {
      return this.accountType === '2'
    }
  },
  methods: {
    handleJoin() {
      this.$bus.$emit('showLoginPop', {type: '1'})
    },
    handleInvite() {
      // this.$bus.$emit('showInvitePop')
      this.$bus.$emit('showPosterPop')
    }
  }
}
</script>

<style lang="scss" scoped>
.mh-banner {
  height: 668px;
  padding-top: 80px;
  background-image: url("@/assets/banner.png");
  // background-position: center;
  background-repeat: no-repeat;
  // background-attachment: fixed;
  background-size: cover;
  position: relative;
  &.account-demo {
    background-image: url("@/assets/banner-demo.png");
  }
  .banner-main {
    width: fit-content;
    padding-top: 180px;
    padding-left: 60px;
    text-align: center;
    .main-title {
      line-height: 40px;
      font-size: 40px;
      font-weight: 700;
      color: var(--minor-color) // #D81517;
    }
    .sub-title {
      line-height: 38px;
      font-size: 32px;
      font-weight: 500;
    }
    .btn-wrap {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .btn-join {
        cursor: pointer;
        width: 240px;
        height: 50px;
        background-color: var(--theme-color);
        border-radius: 10px;
        line-height: 50px;
        font-weight: 500;
        font-size: 20px;
        color: #fff;
        text-align: center;
        &:hover {
          background-color: var(--theme-hover-color);
        }
      }
      .btn-invite {
        cursor: pointer;
        margin-left: 24px;
        width: 240px;
        height: 50px;
        background-color: #fff;
        border-radius: 10px;
        line-height: 50px;
        font-weight: 600;
        font-size: 16px;
        color: var(--theme-color);
        text-align: center;
        // &.demo {
        //   width: 300px;
        // }
        &:hover {
          background-color: #F2F2F2;
        }
      }
    }
  }
}
</style>