<template>
  <div class="join-explain">
    <div class="join-explain-item">
      <div class="join-explain-title">
        <div class="join-explain-title__img">
          <img :src="getIconByName('step_1')" class="rounded mx-auto d-block">
        </div>
        <div class="join-explain-title__text">How to win?</div>
      </div>
      <div class="reward-answer">
        <p>Everyone will <strong>start with $1000.</strong> The top five traders in either <strong>Trading Profit or Trading Volume leaderboards</strong> will share the prizes pool with:</p>
        <p><strong>25% - 10% - 8% - 5% - 2%</strong></p>
        <p>For Trading Profit, it's about who earns the most, while for Trading Volume, it's about who trades the most (regardless of profit or loss)</p>
        <p>The rank and prize pool are re-calculated every week.</p>
      </div>
    </div>
    <div class="join-explain-item">
      <div class="join-explain-title">
        <div class="join-explain-title__img">
          <img :src="getIconByName('step_2')" class="rounded mx-auto d-block">
        </div>
        <div class="join-explain-title__text">How to Join?</div>
      </div>
      <div class="step-detail">
        <!-- step 1 -->
        <div class="step-item">
          <div class="step-num">1</div>
          <div class="step-conent">
            <div class="step-title">Log in / Register</div>
            <div class="step-desc">Create a PRIME Account on MH Market</div>
          </div>
        </div>
        <!-- step 2 -->
        <div class="step-item">
          <div class="step-num">2</div>
          <div class="step-conent">
            <div class="step-title">Fund Your Account</div>
            <div class="step-desc">Deposit or Maintain $1000 in your account</div>
          </div>
        </div>
        <!-- step 3 -->
        <div class="step-item">
          <div class="step-num">3</div>
          <div class="step-conent">
            <div class="step-title">Start Trading</div>
            <div class="step-desc">For every lot you trade, MHMarkets will contribute $1 to the Prize Pool with no limit.</div>
          </div>
        </div>
        <!-- step 4 -->
        <div class="step-item">
          <div class="step-num">4</div>
          <div class="step-conent">
            <div class="step-title">Rank Among the Top</div>
            <div class="step-desc">Make it to the top 5 and win the prize!</div>
          </div>
        </div>        
        <!-- <MhSteps class="step-custom">
          <MhStep height="100px" num="1">Log in or Sign up on <a class="login_link" href="https://www.mhmarkets.com/" target="_blank">MH Markets</a></MhStep>
          <MhStep height="100px" num="2">Deposit or Maintain ${{getDepositAmount}} in your account</MhStep>
          <MhStep height="100px" num="3">Start trading</MhStep>
          <MhStep num="4">Make it to the top 5 and win the prize!</MhStep>
        </MhSteps> -->
        <div class="remind">*If you still have questions, visit our <strong @click="toRules">Rules</strong> page</div>
      </div>
    </div>
    <div class="join-explain-item">
      <div class="join-explain-title">
        <div class="join-explain-title__img">
          <img :src="getIconByName('step_2')" class="rounded mx-auto d-block">
        </div>
        <div class="join-explain-title__text">{{ joinStep3Title }}</div>
        <div class="invite-btn" @click="handleShare"><span>Invite Now</span></div>
      </div>
      <div class="join-explain-content">{{ joinStep3Desc }}</div>
      <div class="rules-link" @click="handleInviteRule">Detailed Rules ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MhSteps from '@/components/MhSteps/index.vue'
import MhStep from '@/components/MhSteps/MhStep.vue'
export default {
  name: 'JoinActivity',
  components: {
    MhSteps,
    MhStep
  },
  computed: {
    ...mapGetters(['accountType']),
    isDemo() {
      return this.accountType === '2'
    },
    getIconByName() {
      return (name) => {
        let imgName = name
        if (this.isDemo) {
          imgName += '-demo'
        }
        return require(`@/assets/images/${imgName}.png`)
      }
    },
    // 入金金额
    getDepositAmount() {
      if (this.isDemo) {
        return '50'
      }
      return '1,000'
    },
    joinStep3Title() {
      if (this.isDemo) {
        return 'Invite your friends'
      }
      return 'Referral program'
    },
    joinStep3Desc() {
      if (this.isDemo) {
        return 'Share the contest with your friends; it is always more enjoyable with more players!'
      }
      return 'The referrer earns a reward of USD$5 for every lot their referred friend trades.'
    }
  },
  methods: {
    toRules() {
      this.$bus.$emit('to-rules')
    },
    handleShare() {
      this.$bus.$emit('showPosterPop')
    },
    handleInviteRule() {
      this.$bus.$emit('showInvitePop')
    }
  },
}
</script>

<style lang="scss" scoped>
.join-explain{
  width: 580px;
  text-align: left;
  .join-explain-title{
    display: flex;
    align-items: center;
    &__img{
      width: 56px;
      height: 56px;
      img{
        max-width: 100%;
        height: auto;
        border-radius: 50%;
      }
    }
    &__text{
      margin-left: 12px;
      font-size: 32px;
      font-weight: 600;
    }
    .invite-btn {
      cursor: pointer;
      margin-left: 48px;
      height: 48px;
      width: 130px;
      color: #142E8A;
      line-height: 48px;
      text-align: center;
      border: 1px solid #142E8A;
      border-radius: 50px;
    }
  }

  .join-explain-item{
    border-radius: 16px;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
    padding: 30px;
    &:not(:first-child) {
      margin-top: 30px;
    }

    .reward-answer {
      margin-top: 20px;
      font-size: 20px;
    }

    .join-explain-content{
      margin-top: 10px;
      font-size: 20px;
      line-height: 32px;
      color: #000;
    }
    .rules-link {
      font-size: 16px;
      font-weight: 600;
      color: var(--theme-color);
      text-decoration: underline;
      cursor: pointer;
    }

    .step-detail{
      margin-top: 20px;
      .step-item {
        height: 100px;
        border-radius: 20px;
        background-color: #F5F5F5;
        padding-left: 30px;
        display: flex;
        align-items: center;
        &:not(:first-child) {
          margin-top: 20px;
        }
        .step-num {
          font-size: 64px;
          font-style: italic;
          color: var(--theme-color);
        }
        .step-conent {
          margin-left: 30px;
          .step-title {
            font-size: 24px;
            font-weight: 600;
          }
          .step-desc {
            font-size: 14px;
          }
        }
      }
      .step-custom {
        margin-left: 30px;
        .login_link {
          font-weight: bold;
          text-decoration: underline;
          color: #0F46B1;
        }
      }
      .remind {
        margin-top: 50px;
        font-size: 16px;
        line-height: 24px;
        strong {
          cursor: pointer;
          line-height: 24px;
          color: #0F46B1;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>