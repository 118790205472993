<template>
  <div class="mh-list">
    <div class="mh-list-wrap">
      <div class="mh-list__head">
        <div class="mh-list__head-item no">Rank</div>
        <div class="mh-list__head-item full">Contestant</div>
        <div class="mh-list__head-item data">{{ type === '1' ? 'Gains': 'No. of Lots' }}</div>
      </div>
      <div class="mh-list__content">
        <slot :listData="listData"></slot>
        <MhPagination v-if="total>10" :currentPage="page" :total="total" @current-change="onCurrentChange" />
      </div>
    </div>
  </div>
</template>

<script>
import MhPagination from '@/components/mh-pagination/index.vue'
export default {
  name: 'MhList',
  components: {
    MhPagination
  },
  props: {
    // 1:利润排行2:手数排行
    type: {
      type: String,
      default: '1'
    },
    apiHttp: {
      type: Function,
      default: () => {}
    },
    queryParams: {
      type: Function
    }
  },
  data() {
    return {
      cacheQuery: {}, // 查询条件
      listData: [],   // 列表数据
      page: 1,    // 当前页号
      limit: 10,  // 每页条数
      total: 0    // 总条数
    }
  },
  mounted() {
    console.log('--mounted--type:', this.type)
    this.refreshList()
  },
  methods: {
    refreshList(isRefresh = false) {
      if (isRefresh) this.page = 1
      this.cacheQuery = this.queryParams()
      this._getListData()
    },
    // 获取列表数据
    _getListData() {
      this.apiHttp({
        ...this.cacheQuery,
        pageNo: this.page,
        pageSize: this.limit
      }).then(res => {
        const { list, total } = res.data
        this.listData = list
        this.total = total
        this.$emit('finish')
      })
    },
    onCurrentChange(page) {
      this.page = page
      this._getListData()
    }
  },
}
</script>

<style lang="scss" scoped>
.mh-list {
  .mh-list-wrap {
    .mh-list__head {
      height: 35px;
      display: flex;
      border-bottom: 1px solid #1E174F;
      .mh-list__head-item{
        font-size: 16px;
        color: #1E174F;
        line-height: 20px;
        &.no {
          width: 70px;
        }
        &.full{
          flex: 1;
        }
        &.data{
          width: 180px;
        }
      }
    }
  }
}
</style>