<template>
  <div class="about">
    <div class="about-intro">
      <div :class="['about-intro-about', {'account-demo': isDemo}]"><h1 class="about-intro-title">About Us</h1></div>
      <p><span>MH Markets</span> is the world’s leading forex and CFD broker. Our comprehensive trading services include CFDs for foreign exchange, commodities, precious metals, indices, cryptos, and more for traders to capitalize on the global markets. Our prime focus remains delivering exceptional trading conditions to retail and institutional clients worldwide.</p>
      <p>We are committed to empowering our clients by providing unparalleled trading conditions, lightning-fast execution, and unwavering customer support to help them succeed in the dynamic world of forex trading. We equip our clients with the knowledge and skills they require to navigate the online forex market efficiently. Educational tools and resources, including video tutorials, documents, daily analysis reports, and free online & offline trainings are supplied timely to build their confidence and make informed trading decisions throughout their forex trading journey.</p>
      <p>Our global presence and diverse clientele, spanning over 150 countries and regions, testify to our expertise. Over the years, MH Markets has established itself as a trusted name in the industry, and we take pride in earning the trust and loyalty of our clients by consistently delivering excellence and exceeding expectations. We measure our success by providing diverse investment opportunities and the most rewarding trading experience with complete fairness.</p>
    </div>
    <div class="regula">
      <div class="regula-title">
        <div class="main-title"><span class="t1">MH Markets</span> <span class="t2">Regulations</span></div>
        <div class="sub-title">Trade with MH Markets. We are licensed and regulated by ASIC, VFSC, MISA and MSB.</div>
      </div>
      <div class="regula-list">
        <div class="regula-list-item">
          <div class="item-img"><img src="@/assets/images/regula_01.png"></div>
          <div class="item-desc">Company Registration Number: 168 652 542<br/>AFSL License Number: 455388<br/>www.asic.gov.au</div>
        </div>
        <div class="regula-list-item">
          <div class="item-img"><img src="@/assets/images/regula_02.png"></div>
          <div class="item-desc">Authorization number: 40306.<br/>www.vfsc.vu</div>
        </div>
        <div class="regula-list-item">
          <div class="item-img"><img src="@/assets/images/regula_03.png"></div>
          <div class="item-desc">MISA License Number: T2023217.<br/>www.mwaliregistrar.com</div>
        </div>
      </div>
      <div class="part-line"></div>
      <div class="regula-list">
        <div class="regula-list-item">
          <div class="item-img"><img src="@/assets/images/regula_04.png"></div>
          <div class="item-desc">MSB license number: M23343315<br/>www.fintrac-canafe.canada.ca</div>
        </div>
        <div class="regula-list-item">
          <div class="item-img"><img src="@/assets/images/regula_05.png"></div>
          <div class="item-desc">License number: GB20026131</div>
        </div>
        <div class="regula-list-item">
          <div class="item-img"><img src="@/assets/images/regula_06.png"></div>
          <div class="item-desc">SVGFSA License Number: 22135</div>
        </div>
      </div>      
    </div>
    <div class="awards-wrap">
      <div class="awards-head">
        <div class="awards-title"><span class="a1">Awards & </span><span :class="['a2', {'account-demo': isDemo}]">Recognition</span></div>
        <div class="awards-desc">Our worldwide recognition for reliability, integrity, customer support, and commitment fills us with great pride to be among the top Forex brokers</div>
      </div>
      <div class="year2024-wrap">
        <div :class="['year2024', {'account-demo': isDemo}]">2024</div>
      </div>
      <div class="year-awards-list">
        <img class="honor-item" src="@/assets/images/honor_2024_01.png">
      </div>
      <div class="year2023-wrap">
        <div :class="['year2023', {'account-demo': isDemo}]">2023</div>
      </div>
      <div class="year-awards-list">
        <img class="honor-item" src="@/assets/images/honor_2023_01.png">
        <img class="honor-item" src="@/assets/images/honor_2023_02.png">
        <img class="honor-item" src="@/assets/images/honor_2023_03.png">
        <img class="honor-item" src="@/assets/images/honor_2023_04.png">
        <img class="honor-item" src="@/assets/images/honor_2023_05.png">
        <img class="honor-item" src="@/assets/images/honor_2023_06.png">
        <img class="honor-item" src="@/assets/images/honor_2023_07.png">
      </div>
      <div class="year2022-wrap">
        <div :class="['year2022', {'account-demo': isDemo}]">2022</div>
      </div>
      <div class="year-awards-list">
        <img class="honor-item" src="@/assets/images/honor_2022_01.png">
        <img class="honor-item" src="@/assets/images/honor_2022_02.png">
        <img class="honor-item" src="@/assets/images/honor_2022_03.png">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'About',
  computed: {
    ...mapGetters(['accountType']),
    isDemo() {
      return this.accountType === '2'
    }
  },
}
</script>

<style lang="scss" scoped>
.about {
  text-align: justify;
  padding-top: 100px;
  .about-intro {
    margin: 0 auto;
    max-width: 1240px;
    // height: 530px;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    .about-intro-about {
      width: 430px;
      height: 116px;
      display: flex;
      align-items: flex-end;
      background-image: url('@/assets/images/about.png');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      &.account-demo {
        background-image: url('@/assets/images/about-demo.png');
      }
      .about-intro-title {
        font-size: 48px;
        font-weight: 600;
        line-height: 58px;
        color: var(--theme-color);
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      color: #000;
      span {
        color: #D81618;
      }
    }
  }

  .regula {
    margin: 0 auto;
    margin-top: 60px;
    width: 1240px;
    .regula-title {
      .main-title {
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        .t1 {
          color: #D81618;
        }
        .t2 {
          color: #000;
        }
      }
      .sub-title {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
      }
    }
    .regula-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .regula-list-item {
        width: 400px;
        .item-desc {
          margin-top: 20px;
          padding-left: 28px;
          padding-right: 28px;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: center;
        }
      }
    }
    .part-line {
      height: 1px;
      width: 1200px;
      background-color: #BDBCC1;
      margin: 20px 0;
    }
  }

  .awards-wrap {
    margin-top: 80px;
    position: relative;
    .awards-head {
      margin: 0 auto;
      max-width: 656px;
      .awards-title {
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        .a1 {
          color: #1E174F;
        }
        .a2 {
          color: #D81618;
          &.account-demo {
            color: #142E8A;
          }
        }
      }
      .awards-desc {
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
        color: #1E174F;
        text-align: center;
      }
    }
    .year2024-wrap {
      margin: 0 auto;
      max-width: 1240px;
      position: relative;
      margin-top: -30px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: calc(50% + 100px);
        height: 2px;
        background-color: var(--theme-color);
        opacity: 0.5;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        left: calc(50% + 100px);
        height: 2px;
        background-color: var(--theme-color);
        opacity: 0.5;
      }

      .year2024 {
        margin: 0 auto;
        width: 502px;
        height: 237px;
        background-image: url('~@/assets/images/2024.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        &.account-demo {
          background-image: url('~@/assets/images/2024-demo.png');
        }

        font-size: 64px;
        font-weight: 600;
        color: var(--theme-color);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .year2023-wrap {
      margin: 0 auto;
      max-width: 1240px;
      position: relative;
      margin-top: -76px;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: calc(50% + 100px);
        height: 2px;
        background-color: var(--theme-color);
        opacity: 0.5;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        left: calc(50% + 100px);
        height: 2px;
        background-color: var(--theme-color);
        opacity: 0.5;
      }
      .year2023 {
        margin: 0 auto;
        max-width: 502px;
        height: 237px;
        background-image: url('~@/assets/images/2023.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        &.account-demo {
          background-image: url('~@/assets/images/2023-demo.png');
        }        

        font-size: 64px;
        font-weight: 600;
        color: var(--theme-color);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .year2022-wrap {
      margin: 0 auto;
      margin-top: -76px;
      max-width: 1240px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: calc(50% + 100px);
        height: 2px;
        background-color: var(--theme-color);
        opacity: 0.5;
      }
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        left: calc(50% + 100px);
        height: 2px;
        background-color: var(--theme-color);
        opacity: 0.5;
      }      
      .year2022 {
        margin: 0 auto;
        max-width: 496px;
        height: 237px;
        background-image: url('~@/assets/images/2022.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;

        &.account-demo {
          background-image: url('~@/assets/images/2022-demo.png');
        }

        font-size: 64px;
        font-weight: 600;
        color: var(--theme-color);
        display: flex;
        justify-content: center;
        align-items: center;
      }      
    }
    .year-awards-list{
      margin: 0 auto;
      max-width: 1340px;
      margin-top: -80px;
      border-radius: 16px;
      background: rgba(248, 248, 248, 0.9);
      padding: 38px 260px 30px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      // gap: 30px 40px;
      .honor-item {
        width: 200px;
        height: 220px;
      }
    }
  }
}
</style>