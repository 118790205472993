<template>
  <div class="mh-step" :style="{ height: height }">
    <div class="mh-step__head">
      <div class="mh-step__line"></div>
      <div class="mh-step__icon">
        <div class="mh-step__icon-inner">{{ num }}</div>
      </div>
    </div>
    <div class="mh-step__main">
      <div class="mh-step__title">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MhStep',
  props: {
    num: {
      type: String
    },
    height: {
      type: String,
      default: 'auto'
    }
  }
}
</script>

<style lang="scss" scoped>
.mh-step {
  display: flex;
  flex-shrink: 1;
  position: relative;
  .mh-step__head {
    position: relative;
    width: 60px;
    flex-grow: 0;
    color: var(--theme-color);
    .mh-step__line {
      position: absolute;
      top: 70px;
      bottom: 10px;
      left: 29px;
      width: 2px;
      border-color: inherit;
      background-color: var(--theme-color);
    }
    .mh-step__icon{
      position: relative;
      z-index: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      font-size: 24px;
      box-sizing: border-box;
      background: #fff;
      transition: .15s ease-out;
      border-radius: 50%;
      border: 2px solid;
      border-color: inherit;
      .mh-step__icon-inner {
        display: inline-block;
        user-select: none;
        text-align: center;
        font-weight: 700;
        line-height: 1;
        color: inherit;
      }
    }
  }
  .mh-step__main {
    flex-grow: 1;
    white-space: normal;
    text-align: left;
    padding-left: 10px;
    .mh-step__title{
      line-height: 48px;
      font-size: 20px;
      padding-bottom: 8px;
      color: #000;      
    }
  }
}
</style>