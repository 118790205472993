const state = {
  accountType: '1' // 账户类型 1:Main, 2:Demo
}

const mutations = {
  SET_ACCOUNTTYPE: (state, accountType) => {
    state.accountType = accountType
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}