<template>
  <div class="activity">
    <MhHeader />
    <MhBanner />
    <MhContent />
    <MhFooter />
    <MhLogin ref="loginPopRef" />
    <MhInvite ref="inviteRef" />
    <PosterPop ref="posterPopRef" />
  </div>
</template>

<script>
import MhHeader from '@/views/MhHeader/index.vue'
import MhBanner from '@/views/MhBanner/index.vue'
import MhContent from '@/views/MhContent/index.vue'
import MhFooter from '@/components/MhFooter/index.vue'
import MhLogin from '@/components/MhLogin/indexV2.vue'
import MhInvite from '@/components/MhInvite/index.vue'
import PosterPop from '@/components/MhInvite/PosterPop.vue'

import { mapActions } from 'vuex'

export default {
  name: 'Activity',
  components: {
    MhHeader,
    MhBanner,
    MhContent,
    MhFooter,
    MhLogin,
    MhInvite,
    PosterPop
  },
  created() {
    this.GetInitData()
    // 获取实时数据
    this.GetRealtimeData()
    // 订阅公共事件
    this.subscribe()
  },
  methods: {
    ...mapActions('realtime', [
      'GetInitData',
      'GetRealtimeData'
    ]),
    subscribe() {
      // 登录Pop
      this.$bus.$on('showLoginPop', (params) => {
        this.$refs.loginPopRef.show(params)
      })
      // 邀请Pop
      this.$bus.$on('showInvitePop', () => {
        this.$refs.inviteRef.show()
      })
      // 海报Pop
      this.$bus.$on('showPosterPop', () => {
        this.$refs.posterPopRef.show()
      })
    }
  },
}
</script>
