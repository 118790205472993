<template>
  <div class="rules-example">
    <div class="rules-example__title">Participation Example</div>
    <div class="rules-example__content">
      <div :class="['rules-example__content-item','col1', {'account-demo': isDemo}]">
        <div class="col_text">1.Register PRIME <br/>Account on Tuesday</div>
      </div>
      <div :class="['rules-example__content-item','col2', {'account-demo': isDemo}]">
        <div class="col_text">2.Account Verified,<br/>Deposit ${{getDepositAmount}}</div>
      </div>
      <div :class="['rules-example__content-item','col3', {'account-demo': isDemo}]">
        <div class="col_text">3.Click “Join Now”</div>
      </div>
      <div :class="['rules-example__content-item','col4', {'account-demo': isDemo}]">
        <div class="col_text">4.Trading Data Begins<br/> Calculation</div>
      </div>
      <div :class="['rules-example__content-item','col5', {'account-demo': isDemo}]">
        <div class="col_text">5.Trades from Tuesday to Friday</div>
      </div>
      <div :class="['rules-example__content-item','col6', {'account-demo': isDemo}]">
        <div class="col_text">6.Ranked 5th in Trading<br/> Profit Leaderboard & 3rd in<br/> the Trading Volume<br/> Leaderboard.</div>
      </div>
      <div :class="['rules-example__content-item','col7', {'account-demo': isDemo}]">
        <div class="col_text">7.Receives 2% of prize from profit leaderboard pool and 8% of prize from Volume Leaderboard pool.</div>
      </div>
      <div :class="['rules-example__content-item','col8', {'account-demo': isDemo}]">
        <div class="col_text">8.Prize money deposited<br/> into PRIME Account on Sunday</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ParticipationExample',
  computed: {
    ...mapGetters(['accountType']),
    isDemo() {
      return this.accountType === '2'
    },
    // 入金金额
    getDepositAmount() {
      if (this.isDemo) {
        return '50'
      }
      return '1,000'
    },
    getExampleDesc3() {
      if (this.isDemo) {
        return 'Entry Forex Trading Contest'
      }
      return 'Premium Forex Trading Contest'
    }
  }
}
</script>

<style lang="scss" scoped>
  .rules-example {
    // margin-top: 80px;
    width: 700px;
    .rules-example__title {
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      color: #1E174F;
      text-align: center
    }
    .rules-example__content {
      // padding-left: 20px;
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      .rules-example__content-item {
        width: 350px;
        height: 350px;
        padding: 145px 45px 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.col1 {
          background-image: url('~@/assets/images/exa_01.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_01-demo.png');
          }
        }
        &.col2 {
          background-image: url('~@/assets/images/exa_02.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_02-demo.png');
          }
        }
        &.col3 {
          background-image: url('~@/assets/images/exa_03.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_03-demo.png');
          }
        }
        &.col4 {
          background-image: url('~@/assets/images/exa_04.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_04-demo.png');
          }
        }
        &.col5 {
          background-image: url('~@/assets/images/exa_05.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_05-demo.png');
          }
        }
        &.col6 {
          background-image: url('~@/assets/images/exa_06.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_06-demo.png');
          }
        }
        &.col7 {
          background-image: url('~@/assets/images/exa_07.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_07-demo.png');
          }
        }
        &.col8 {
          background-image: url('~@/assets/images/exa_08.png');
          background-size: 100% 100%;
          &.account-demo {
            background-image: url('~@/assets/images/exa_08-demo.png');
          }
        }
        .col_text {
          // padding: 10px 20px;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          text-align: center;
        }
      }
    }
  }
</style>