<template>
  <div class="current-round__leaderboard volume">
    <div class="leaderboard__head">
      <div class="leaderboard__title">Top Volume Leaderboard</div>
      <div class="leaderboard__end_time">{{ endtimeText }}</div>
    </div>
    <div class="leaderboard__list">
      <MHList 
        ref="listRef" 
        type="2" 
        :apiHttp="apiRanking" 
        :queryParams="getQueryParams" 
        @finish="onFinish" 
        style="margin-top: 20px;">
        <template slot-scope="{listData}">
          <div class="list__row" v-for="(item, index) of listData" :key="index">
            <div class="list__row-item col1">{{ item.score }}</div>
            <div class="list__row-item col2">
              <img class="user-avatar" src="@/assets/images/user.png">
              <div class="user-name">{{ item.nickname }}</div>
            </div>
            <div class="list__row-item col3">{{ item.value }}</div>
          </div>
        </template>
      </MHList>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { apiRanking } from '@/api/activity'
import { getGMT3Time } from '@/utils/index'

import MHList from '@/components/mh-list/index.vue'
export default {
  name: 'VolumeLeader',
  components: {
    MHList
  },
  data() {
    return {
      timer: null,
      delay: 10 * 1000
    }
  },
  computed: {
    ...mapState({
      endtime: state => state.realtime.endtime
    }),
    endtimeText() {
      if (this.endtime) {
        return getGMT3Time(this.endtime)
      } else {
        return '--'
      }
    }
  },
  methods: {
    apiRanking,
    getQueryParams() {
      return { type: '2' }
    },
    onFinish() {
      // 清除定时任务
      clearTimeout(this.timer)
      // 重新开始任务
      this.timer = setTimeout(() => {
        console.log('---定时请求VolumeLeader')
        this.$refs.listRef.refreshList()
      }, this.delay)
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer) // 清除定时任务
  }  
}
</script>

<style lang="scss" scoped>
.current-round__leaderboard {
  width: 650px;
  height: 1200px;
  border-radius: 16px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  padding: 50px;
  .leaderboard__head {
    .leaderboard__title {
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;
    }
    .leaderboard__end_time {
      font-size: 20px;
      line-height: 24px;
      color: var(--theme-minor-color);
      margin-top: 20px;
    }
  }
  .leaderboard__list {
    .list__row {
      height: 90px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      .list__row-item {
        &.col1 {
          width: 70px;
          font-size: 16px;
        }
        &.col2 {
          flex: 1;
          display: flex;
          align-items: center;
          .user-avatar {
            width: 50px;
            height: 50px;
          }
          .user-name {
            margin-left: 20px;
            font-size: 20px;
            font-weight: 600;
          }
        }
        &.col3 {
          width: 180px;
          font-size: 20px;
          font-weight: 600;
          color: #00B43B;
        }
      }
    }        
  }
}
</style>