<template>
  <div class="m-header fixed-top">
    <div class="m-header-wrap mh-flex-between">
      <div class="nav-bar" @click="show"><i class="bi-list"></i></div>
      <div class="nav-brand"><img src="~@/assets/s-logo.png" width="124" height="28"></div>
      <div></div>
    </div>
    <div class="m-sub-nav" v-if="showSubNav">
      <div class="m-sub-header mh-flex-between">
        <div class="nav-close" @click="show"><i class="bi-x-lg" style="font-size: 24px;"></i></div>
        <div class="nav-brand"><img src="~@/assets/s-logo.png" width="124" height="28"></div>
        <div></div>
      </div>
      <div class="m-sub-main mh-flex-center flex-column">
        <div class="btn-open">Open Account</div>
        <div class="btn-login">Login</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MHeader',
  data() {
    return {
      showSubNav: false
    }
  },
  methods: {
    show() {
      this.showSubNav = !this.showSubNav
    }
  },
}
</script>

<style lang="scss" scoped>
.m-header {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
  .m-header-wrap {
    height: 80px;
    padding: 0 30px;
    .bi-list {
      font-size: 24px;
    }
  }
  .m-sub-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background: linear-gradient(87.59deg, #EDF2F6 0%, #CED5DF 100%);
    .m-sub-header {
      height: 80px;
      padding: 0 30px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
    }
    .m-sub-main {
      padding-top: 60px;
      .btn-open {
        width: 74%;
        height: 48px;
        background-color: #C6222F;
        border-radius: 24px;
        line-height: 48px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
      }
      .btn-login {
        margin-top: 15px;
        width: 74%;
        height: 48px;
        border: 1px solid #C6222F;
        border-radius: 24px;
        line-height: 48px;
        color: #C6222F;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>