<template>
  <div class="sign-panel">
    <el-form ref="signFormRef" :model="signForm" :rules="signRules">
      <el-form-item label="First Name" prop="firstName">
        <el-input v-model="signForm.firstName"></el-input>
      </el-form-item>
      <el-form-item label="Last Name" prop="lastName">
        <el-input v-model="signForm.lastName"></el-input>
      </el-form-item>
      <el-form-item label="Email Address" prop="email">
        <el-input v-model="signForm.email"></el-input>
      </el-form-item>
      <el-form-item label="Password" prop="password">
        <el-input type="password" v-model="signForm.password" autocomplete="new-password"></el-input>
      </el-form-item>
      <el-form-item label="Confirm Password" prop="confirmPwd">
        <el-input type="password" v-model="signForm.confirmPwd" autocomplete="new-password"></el-input>
      </el-form-item>
      <el-form-item label="Invitation Code" prop="inviteCode">
        <el-input v-model="signForm.inviteCode"></el-input>
      </el-form-item>
      <el-form-item class="agree-terms">
        <el-checkbox v-model="signForm.isJoinEmail" :true-label="1" :false-label="0">I wish to join MH Market’s mailing list</el-checkbox>
        <el-checkbox v-model="signForm.isNotify" :true-label="1" :false-label="0">I wish to receive contest notifications</el-checkbox>
      </el-form-item>
      <el-form-item class="btn-wrap">
        <el-button type="primary" class="btn-login" @click="submitForm">Sign Up</el-button>
      </el-form-item>
    </el-form>
    <div class="sign-explain">
      By clicking "Sign Up", you agree to our <span>Privacy Policy</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { goToUserBackend } from '@/utils/index'
import { apiRegister } from '@/api/user'

export default {
  name: 'sign-panel',
  data() {
    return {
      signForm: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPwd: '',
        inviteCode: '',
        isJoinEmail: 1,
        isNotify: 1
      },
      signRules: {
        firstName: [{
          required: true, message: 'Please enter First Name', trigger: 'blur'
        }, {
          min: 2, max: 20, message: '2 to 20 characters in length'
        }],
        lastName: [{
          required: true, message: 'Please enter Last Name', trigger: 'blur'
        }, {
          min: 2, max: 20, message: '2 to 20 characters in length'
        }],
        email: [{
          required: true, message: 'Please enter Email', trigger: 'blur'
        }, {
          type: 'email', message: 'please enter your vaild email', trigger: 'blur'
        }],
        password: [{
          required: true, message: 'Please enter Password', trigger: 'blur'
        }, {
          min: 8, max: 20, message: 'Password length is between 8-20', trigger: 'blur'
        }, {
          trigger: 'blur', validator: (rule, value, callback) => {
            // 必须包含字母和数字
            const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]+$/
            if (!regex.test(value)) {
              callback(new Error('Password must contain letters and numbers'))
            } else {
              callback()
            }
          }
        }, {
          trigger: 'blur', validator: (rule, value, callback) => {
            if (value) {
              if(this.signForm.confirmPwd !== '') {
                this.$refs.signFormRef.validateField('confirmPwd')
              }
              callback()
            }
          }
        }],
        confirmPwd: [{
          required: true, message: 'Please enter Confirm Password', trigger: 'blur'
        }, {
          trigger: 'blur', validator: (rule, value, callback) => {
            if (value !== this.signForm.password) {
              callback(new Error('The passwords entered twice are inconsistent'))
            } else {
              callback()
            }
          }
        }]
      }
    }
  },
  computed: {
    ...mapGetters(['accountType'])
  },
  mounted() {
    const { inviteCode } = this.$route.query
    this.signForm.inviteCode = inviteCode
  },
  methods: {
    submitForm() {
      this.$refs.signFormRef.validate((valid) => {
        if (valid) {
          apiRegister({...this.signForm, accountType: this.accountType}).then((res) => {
            if (res.code === 200) {
              goToUserBackend(res.token)
            }
          })
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.sign-panel {
  padding-top: 30px;
  .el-form {
    .agree-terms {
      ::v-deep .el-form-item__content {
        line-height: 20px;
      }
      .el-checkbox {
        display: flex;
        align-items: center;
        ::v-deep .el-checkbox__label {
          color: #000;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .btn-wrap {
      text-align: center;
      margin-top: 50px;
      .btn-login {
        width: 300px;
        height: 50px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 600;        
      }
    }
  }
  .sign-explain {
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #000;
    span {
      font-weight: 600;
      color: #1654F3;
    }
  }
}
</style>