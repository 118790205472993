<template>
  <div class="archive">
    <CustomPagination :pageSize="limit" :total="total" @current-change="onCurrentChange" />
    <div class="round-list">
      <Round class="mg" :roundData="item" v-for="(item, index) in listData" :key="index" />
    </div>
  </div>
</template>

<script>
import { apiArchiveList } from '@/api/activity'

import Round from './Round.vue'
import CustomPagination from './CustomPagination.vue'
export default {
  name: 'Archive',
  components: {
    Round,
    CustomPagination
  },
  data() {
    return {
      page: 1,
      limit: 3,
      listData: [],
      total: 0
    }
  },
  mounted() {
    this.hisRoundList()
  },
  methods: {
    // 历史排行
    hisRoundList() {
      const params = {
        pageNo: this.page,
        pageSize: this.limit
      }
      apiArchiveList({ ...params }).then((res) => {
        const { list, total } = res.data
        this.listData = list
        this.total = total
      })
    },
    onCurrentChange(page) {
      this.page = page
      this.hisRoundList()
    }
  }
}
</script>

<style lang="scss" scoped>
.archive {
  padding-top: 100px;
  .round-list {
    padding-top: 38px;
    .mg:not(:first-child) {
      margin-top: 30px;
    }    
  }
}
</style>