<template>
  <div class="round-list">
    <div class="list-head">{{ title }}</div>
    <div class="list-body">
      <div class="list-row" v-for="(item, index) of userList" :key="index">
        <div class="list-row-col col1">
          <img class="user-avatar" src="@/assets/images/user.png">
          <div class="user-name">{{ item.username }}</div>
        </div>
        <div class="list-row-col col2">{{ item.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoundList',
  props: {
    title: {
      type: String,
      default: ''
    },
    userList: {
      type: Array,
      default: []
    }
  },
  mounted() {
    console.log('----this.userList:', this.userList)
  }
}
</script>

<style lang="scss" scoped>
.round-list {
  width: 554px;
  height: 540px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  padding: 50px 20px 30px;
  .list-head {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    border-bottom: 2px solid rgba(30, 23, 79, 0.5);
    padding-bottom: 15px;
    text-align: center;
  }
  .list-body {
    padding-left: 20px;
    padding-right: 20px;
    .list-row {
      height: 80px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-bottom: none;
      }
      .list-row-col {
        flex: 1;
        &.col1 {
          display: flex;
          // justify-content: center;
          align-items: center;
          .user-avatar {
            width: 50px;
            height: 50px;
          }
          .user-name {
            margin-left: 20px;
            font-weight: 500;
            font-size: 20px;
          }
        }
        &.col2 {
          font-size: 20px;
          color: #00B43B;
          line-height: 24px;
        }
      }
    }
  }
}
</style>