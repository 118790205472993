import { apiInitData } from '@/api/activity'

const state = {
  activity: {
    round: 1, // 当前活动
    membersTotal: 0, // 参与人数
    prizeAmount: 0, // 资金池
  },
  endtime: '', // 活动结束时间
  referralId: '', // 推广Id
  referralLink: '' // 推广链接
}
const mutations = {
  SET_ACTIVITY: (state, activity) => {
    state.activity = activity
  },
  SET_ENDTIME: (state, endtime) => {
    state.endtime = endtime
  },
  SET_REFERRALID(state, referralId) {
    state.referralId = referralId
  },
  SET_REFERRALLINK(state, referralLink) {
    state.referralLink = referralLink
  }
}
const actions = {
  GetInitData({ commit }) {
    apiInitData().then(res => {
      const { activity, endTime, referralId, referralLink } = res.data
      commit('SET_ACTIVITY', activity)
      commit('SET_ENDTIME', endTime) // 2024-05-10 18:00:00
      commit('SET_REFERRALID', referralId)
      commit('SET_REFERRALLINK', referralLink)
    })
  },
  GetRealtimeData({ commit }) {
    const socket = io(process.env.VUE_APP_BASE_API)
    socket.on('event', (data) => {
      commit('SET_ACTIVITY', data.activity)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
