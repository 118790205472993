<template>
  <div class="mh-footer main-fluid">
    <div class="footer-container">
      <div class="footer-head">
        <img class="footer-logo" src="@/assets/images/logo-v.png">
        <div class="footer-social">
          <div class="footer-social-title">Follow us on</div>
          <div class="footer-social-list">
            <img class="social" src="@/assets/images/f01.png" @click="handleFacebook">
            <img class="social" src="@/assets/images/f02.png" @click="handleTwitter">
            <img class="social" src="@/assets/images/f03.png" @click="handleInstagram">
            <img class="social" src="@/assets/images/f04.png" @click="handleLinkedIn">
            <img class="social" src="@/assets/images/f05.png" @click="handleYoutobe">
          </div>
        </div>
      </div>
      <div class="footer-content">
        <div class="footer-content-title">Disclaimer :</div>
        <p>MH Markets is a financial services group offering a wide range of financial products with licenses spanning across different parts of the world. We provide best-in-class products and services with the aim of meeting the needs of the most sophisticated retail and institutional clients in various jurisdictions. With extensive experience in financial markets and strong industry knowledge, the scale of MH Markets continues to expand. We provide specific licensed financial services in different jurisdictions to meet the increasing financial needs of diverse clients. At present, MH Markets has established specialized service organizations for clients in different markets.</p>
        <p>Mohicans Markets Limited is registered with Financial Transactions and Reports Analysis Centre of Canada (FINTRAC) with Money Services Business (MSB) registration no. M23343315. The registered MSB activities include foreign exchange dealing, money transferring, dealing in virtual currencies, and payment service provider.</p>
        <p>Mohicans Markets Capital Pty Ltd is registered in Australia with license number 455388 and with Australian Company Number (ACN) 168 652 542. It is regulated by the Australian Securities and Investments Commission (ASIC) and is authorized to provide general financial product advice for different classes of financial products such as foreign exchange contracts to retail and wholesale clients.</p>
        <p>Mohicans Markets Mauritius holds an Investment Dealer license (Full-service dealer, excluding underwriting) licensed by the Mauritius Financial Services Commission (MFSC), with license number GB20026131. Mohicans Markets Mauritius is an Investment Dealer (liquidity provider/market maker) that offers Brokerage services to its clients. It acts as an investment dealer and a counterparty for the execution of clients' trades. The Company acts as an investment dealer and deal as a principal in Financial Derivative Instruments and Securities and as a broker in predominately financial derivatives traded over the counter, through a recognized trading platform, with different underlying assets such as currency pairs, commodities, bonds, shares and indices.</p>
        <p>Mohicans Markets Limited is authorized by the Vanuatu Financial Services Commission (VFSC), with company number 40306, to carry on the business of dealing with securities.</p>
        <p>Mohicans Markets Limited - Dubai branch, with Business License No. 1289522 representative office of the Mohicans Markets Limited in Vanuatu.</p>
        <p>Mohicans Markets Limited is registered in Saint Vincent and the Grenadines Financial Services Authority, with business license number 22135 BC 2014. The business activity registered is for forex trading/ brokerage. The SV&G FSA is empowered to regulate, supervise and develop the non-bank financial services sector in St. Vincent and the Grenadines. It is also responsible for ensuring that each licensed financial entity is properly managed and remains financially sound. The FSA therefore has the powers to intervene in the affairs of a regulated entity for the purpose of protecting customers'. Further details on the jurisdiction SV&G FSA can be accessed through Financial Services Authority - St. Vincent & the Grenadines (svgfsa.com)</p>
        <p>There are financial services that are not directly offered via this website and we only service customers in accordance with the licenses and registration per jurisdictions mentioned above. If you'd like to receive financial services or are interested in a specific financial product, please contact us and we will pass your details directly to our authorized related business entity who will contact you directly. Please click this link and provide your details.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MhFooter',
  methods: {
    handleFacebook() {
      window.open('https://bit.ly/3Mx6cOi')
    },
    handleTwitter() {
      window.open('https://bit.ly/3u4dJxs')
    },
    handleInstagram() {
      window.open('https://bit.ly/49rf3Le')
    },
    handleLinkedIn() {
      window.open('https://bit.ly/3QOPqwg')
    },
    handleYoutobe() {
      window.open('https://www.youtube.com/channel/UCTFYE8rfwSNGmvRzfKmryWA')
    }
  }
}
</script>

<style lang="scss" scoped>
.mh-footer {
  // height: 480px;
  background-color: #020202;
  padding-top: 80px;
  padding-bottom: 80px;
  .footer-container {
    margin: 0 auto;
    max-width: 1240px;
    text-align: justify;
    .footer-head {
      border-bottom: 2px solid #BF204F;
      padding-bottom: 20px;
      padding-left: 40px;
      padding-right: 40px;
      display: flex;
      justify-content: space-between;
      .footer-logo {
        width: 152px;
        height: 80px;
      }
      .footer-social {
        width: 280px;
        .footer-social-title {
          font-size: 20px;
          font-weight: 600;
          color: #D71618;
          line-height: 24px;
        }
        .footer-social-list {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          .social {
            cursor: pointer;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    .footer-content {
      margin-top: 50px;
      .footer-content-title {
        font-size: 14px;
        font-weight: 700;
        color: #C6222F;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        color: #fff;
        a {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>