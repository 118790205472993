<template>
  <div :class="['custom-pagination', {'account-demo': isDemo}]">
    <div class="go-page">
      <label class="go-page-label">Go to Round</label>
      <el-input 
        class="input-page el-pagination__editor"
        type="number" 
        v-model="inputPageNo"
        :min="1"
      />
      <div class="btn-go" @click="handleGo">Go<i class="el-icon-arrow-right" style="font-weight:600;"></i></div>
    </div>
    <MhPagination ref="paginRef" :currentPage="page" :pageSize="pageSize" :total="total" @current-change="onCurrentChange" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MhPagination from '@/components/mh-pagination/index.vue'

export default {
  name: 'CustomPagination',
  components: {
    MhPagination
  },
  computed: {
    ...mapGetters(['accountType']),
    isDemo() {
      return this.accountType === '2'
    }
  },
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      page: 1,
      inputPageNo: ''
    }
  },
  methods: {
    handleGo() {
      const paginRef = this.$refs.paginRef
      const pageCount = paginRef.internalPageCount
      if (this.inputPageNo < 1) {
        this.page = 1
      } else if (this.inputPageNo > pageCount) {
        this.page = pageCount
      } else {
        this.page = this.inputPageNo
      }
      this.$refs.paginRef.emitChange()
      this.inputPageNo = null
    },
    onCurrentChange(page) {
      this.page = page
      this.$emit('current-change', this.page)
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-pagination {
  margin: 0 auto;
  width: 1340px;
  height: 100px;
  background-color: #F8F8F8;
  // box-shadow: 0px 4px 30px -5px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-left: 100px;
  padding-right: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.account-demo {
    background-color: #E5EBFF;
    .go-page {
      color: #142E8A !important;
    }
  }

  .go-page {
    display: flex;
    align-items: center;
    // color:#EE6B23;
    color: #BF204F;
    .go-page-label {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
    }
    .input-page {
      margin-left: 20px;
    }
    .btn-go {
      user-select: none;
      margin-left: 20px;
      font-weight: 600;
      font-size: 16px;
    }
  }
  .mh-pagination {
    margin-top: 0;
    margin-left: 100px;
  }
}
</style>