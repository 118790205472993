<template>
  <MhDialog
    class="mh-invite"
    ref="dialogRef"
    width="540px"
    top="20px"
  >
    <div class="pop-wrap">
      <img class="banner-gold" src="~@/assets/images/gold.png">
      <div class="btn-close" @click="hide"><i class="el-icon-circle-close icon-close"></i></div>
      <div class="pop-main">
        <div class="pop-title">INVITE TO GET MORE!</div>
        <div class="pop-desc">
          <p>Invite now to earn a reward of <span class="prize">USD$5</span></p>
          <p>for every lot your referred friend trades.</p>
        </div>
        <div class="division-line"></div>
        <div class="invite-rule">
          <div class="invite-rule-title">RULES</div>
          <ul class="rule-list">
            <li>Every lot the Prime invitee trade, the Prime inviter will be granted a $5 withdrawn-able and losable bonus in their campaign account with no cap. However, the inviter will only receive the bonus if the person they invite joins with a $1000 Real Account instead of a $50 Demo Account.</li>
            <li>The “Weekly Trading Contest” hereby referred to as “Promotion” is applicable only to the new and existing Prime Account.</li>
            <li>The promotion is applicable only to the Direct Customers, any registered IB, KOLs, partners of MHMarkets are not allowed to participant;</li>
            <li>MHMarkets reserves the right to change the Terms and Conditions of the Promotion and/or postpone, suspend, or terminate the Promotion at any time, without prior notice and without liability to participants.</li>
            <li>MHMarkets reserves the right to disqualify any participant and/or to reject granting a Cash Rebate if it has grounds to believe that it will be used against its rules and policies, users engaging in violations (such as scalping, hedging, arbitrage, etc.) will be disqualified, and in severe cases, accounts will be frozen.</li>
          </ul>
        </div>
        <!-- <div class="btn-invite" @click="handleInvite">Invite Now</div> -->
      </div>
    </div>
  </MhDialog>
</template>

<script>
import MhDialog from '@/components/mh-dialog/index.vue'
export default {
  name: 'mh-invite',
  components: {
    MhDialog,
  },
  methods: {
    show() {
      this.$refs.dialogRef.show()
    },
    hide() {
      this.$refs.dialogRef.hide()
    },
    handleInvite() {
      this.hide()
      this.$bus.$emit('showPosterPop')
    }
  },
}
</script>

<style lang="scss" scoped>
.mh-invite {
  ::v-deep .el-dialog {
    // border-radius: 12px;
    background-color: transparent;
    box-shadow: none;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      .pop-wrap {
        width: 540px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;
        position: relative;
        .banner-gold {
          position: absolute;
          top: 0;
          left: 0;
          width: 540px;
          height: 360px;
          object-fit: contain;
        }
        .btn-close {
          position: absolute;
          right: 0;
          top: 60px;
          right: 60px;
          width: 40px;
          height: 40px;
          .icon-close {
            font-size: 40px;
            color: #fff;
          }
        }
        .pop-main {
          width: 460px;
          border-radius: 12px;
          background: linear-gradient(180deg, #313841 0%, #1F2227 100%);
          box-shadow: 0px 4px 10px 0px #00000040;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding-top: 330px;
          padding-bottom: 40px;
          .pop-title {
            font-weight: 700;
            font-size: 28px;
            color: #C6222F;
            // line-height: 58px;
          }
          .pop-desc {
            margin-top: 10px;
            text-align: center;
            font-size: 14px;
            color: #fff;
            p {
              margin-bottom: 0;
              .prize {
                font-weight: 700;
                color: #F3D77D;
              }
            }
          }
          .btn-invite {
            user-select: none;
            margin-top: 30px;
            width: 200px;
            height: 40px;
            background-color: #C6222F;
            border-radius: 50px;
            line-height: 40px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            color: #fff;
          }
          .division-line {
            height: 1px;
            width: 428px;
            border: 1px solid #FFFFFF80;
            margin-top: 20px;
            margin-bottom: 20px;
          }
          .invite-rule {
            padding-left: 40px;
            padding-right: 40px;
            .invite-rule-title {
              font-size: 40px;
              font-weight: 900;
              line-height: 48px;
              color: #fff;
              text-align: center;
            }
            .rule-list {
              // margin-top: 20px;
              // margin-left: 30px;
              li {
                list-style: decimal;
                font-size: 14px;
                color: #fff;
                // padding-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>