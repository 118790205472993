<template>
  <nav :class="['mh-pagination', {'account-demo': isDemo}]">
    <div class="mh-pagination__prev-btn" :class="{ 'disabled': internalCurrentPage === 1 }" @click="handlePrev">
      <svg-icon class-name="arrow-left-icon" icon-class="arrow-left"/>
    </div>
    <ul class="mh-pagination__wrap" @click="handlePagerClick">
      <li :class="['page-num', {'active': internalCurrentPage === 1}]" v-if="internalPageCount > 0">1</li>
      <!-- 快退 quickprev -->
      <li
        class="quickprev more"
        v-if="showPrevMore"
        @mouseenter="onMouseenter('left')"
        @mouseleave="quickprevIconClass = 'more'"
      >
        <svg-icon class-name="more-icon" :icon-class="quickprevIconClass"/>
      </li>
      <li :class="['page-num', {'active': internalCurrentPage === item}]" v-for="item of pagers" :key="item">{{ item }}</li>
      <!-- 快进 quicknext -->
      <li
        class="quicknext more"
        v-if="showNextMore"
        @mouseenter="onMouseenter('right')"
        @mouseleave="quicknextIconClass = 'more'"
      >
        <svg-icon class-name="more-icon" :icon-class="quicknextIconClass"/>
      </li>
      <li :class="['page-num', {'active': internalCurrentPage === internalPageCount}]" v-if="internalPageCount > 1">{{ internalPageCount }}</li>
    </ul>
    <div class="mh-pagination__next-btn" :class="{ 'disabled': internalCurrentPage === internalPageCount }"  @click="handleNext">
      <svg-icon class-name="arrow-right-icon" icon-class="arrow-right"/>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MhPagination',
  props: {
    // 当前页号
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    // 总数量
    total: {
      type: Number,
      default: 1
    },
    // 页码按钮数量
    pagerCount: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      // 组件内部当前页
      internalCurrentPage: 1,
      showPrevMore: false,
      showNextMore: false,
      quickprevIconClass: 'more',
      quicknextIconClass: 'more'
    }
  },
  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.internalCurrentPage = parseInt(val) || 1
      }
    },
    internalCurrentPage: {
      immediate: true,
      handler(newVal) {
        this.$emit('update:currentPage', newVal)
      }
    }
  },
  computed: {
    ...mapGetters(['accountType']),
    isDemo() {
      return this.accountType === '2'
    },
    // 内部页数总数量
    internalPageCount() {
      if (typeof this.total === 'number') {
        return Math.max(1, Math.ceil(this.total / this.pageSize))
      }
      return null
    },
    // 页码按钮数量区间
    pagers() {
      const pagerCount = this.pagerCount
      const halfPagerCount = (pagerCount - 1) / 2 // 页码数量一半

      const currentPage = this.internalCurrentPage
      const pageCount = this.internalPageCount

      let showPrevMore = false
      let showNextMore = false

      if(pageCount > pagerCount) {
        if (currentPage > pagerCount - halfPagerCount) {
          showPrevMore = true
        }

        if (currentPage < pageCount - halfPagerCount) {
          showNextMore = true
        }
      }

      const array = []
      // 左边更多按钮
      if (showPrevMore && !showNextMore) {
        const startPage = pageCount - (pagerCount - 2)
        for (let i = startPage; i < pageCount; i++) {
          array.push(i)
        }
      // 右边更多按钮
      } else if (!showPrevMore && showNextMore) {
        for (let i = 2; i < pagerCount; i++) {
          array.push(i)
        }
      } else if (showPrevMore && showNextMore) {
        const offset = Math.floor(pagerCount / 2) - 1
        for (let i = currentPage - offset ; i <= currentPage + offset; i++) {
          array.push(i)
        }
      } else {
        for (let i = 2; i < pageCount; i++) {
          array.push(i);
        }
      }

      this.showPrevMore = showPrevMore
      this.showNextMore = showNextMore
      return array
    }
  },
  methods: {
    // 上一页
    handlePrev() {
      this.internalCurrentPage = this.internalCurrentPage - 1
      this.emitChange()
    },
    // 下一页
    handleNext() {
      this.internalCurrentPage = this.internalCurrentPage + 1
      this.emitChange()
    },
    // 发送change事件
    emitChange() {
      this.$nextTick(() => {
        this.$emit('current-change', this.internalCurrentPage)
      })
    },
    // 鼠标进入
    onMouseenter(direction) {
      if (direction === 'left') {
        this.quickprevIconClass = 'arrow-left-more'
      } else {
        this.quicknextIconClass = 'arrow-right-more'
      }
    },
    handlePagerClick(event) {
      const target = event.target
      if (target.tagName === 'UL') return

      let newPage = Number(event.target.innerText)
      const pageCount = this.internalPageCount
      const currentPage = this.internalCurrentPage
      const pagerCountOffset = this.pagerCount - 2

      if(target.className.indexOf('more') !== -1) {
        if (target.className.indexOf('quickprev') !== -1) {
          newPage = currentPage - pagerCountOffset
        } else if (target.className.indexOf('quicknext') !== -1) {
          newPage = currentPage + pagerCountOffset
        }
      }

      /* istanbul ignore if */
      if (!isNaN(newPage)) {
        if (newPage < 1) {
          newPage = 1
        }

        if (newPage > pageCount) {
          newPage = pageCount
        }
      }

      if (newPage !== currentPage) {
        this.internalCurrentPage = newPage
        this.emitChange()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mh-pagination {
  user-select: none;
  margin-top: 20px;
  display: flex;
  ul {
    padding: 0;
    margin: 0;    
    list-style: none;
  }
  .mh-pagination__prev-btn, .mh-pagination__next-btn {
    width: 32px;
    height: 48px;
    padding-left: 6px;
    display: flex;
    align-items: center;
    &.disabled {
      pointer-events: none;
      .arrow-left-icon {
        color: #bbb;
      }
      .arrow-right-icon {
        color: #bbb;
      }
    }
  }
  .mh-pagination__wrap {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    li {
      cursor: pointer;
    }

    .quickprev, .quicknext{
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        border-radius: 50%;
        background-color: #F2F2F2;
      }
      .more-icon {
        pointer-events: none;
        color: #666;
        cursor: pointer;
        font-size: 16px;
        vertical-align: middle;
      }
    }

    .page-num {
      margin-left: 20px;
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      border-radius: 50%;
      font-size: 16px;
      color: #000;
      &:hover {
        background-color: #F2F2F2;
      }
      &.active {
        background-color: var(--theme-select-bg);
        color: #EE6B23;
        font-weight: 600;
      }
    }
    // .mh-pagination__page {
    //   padding: 0 4px;
    //   .page-num {
    //     width: 48px;
    //     height: 48px;
    //     line-height: 48px;
    //     text-align: center;
    //     border-radius: 50%;
    //     font-size: 16px;
    //     color: #000;
    //     &.active {
    //       background-color: #FBA62066;
    //     }
    //   }
    // }
  }
  &.account-demo {
    .mh-pagination__wrap {
      .page-num {
        &:hover {
          background-color: #F2F2F2;
        }
        &.active {
          background-color: #C6D2FD;
          color: #142E8A;
        }
      }
    }
  }
}
</style>