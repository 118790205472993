<template>
  <MhDialog
    class="poster-pop"
    ref="dialogRef"
    width="600px"
    top="20px"
  >
    <div class="pop-wrap">
      <div class="btn-close" @click="closeHandle"><i class="el-icon-circle-close icon-close"></i></div>
      <div class="poster-wrap" ref="htmlCanvas">
        <div class="logo"><img src="~@/assets/logo.png"></div>
        <div class="curr-round">
          <!-- <div class="item round">
            <div class="title">Round</div>
            <div class="num">{{ activity.round }}</div>
          </div> -->
          <div class="item prize">
            <div class="title">PRIZE POOL</div>
            <div class="num">${{ getPrizeTotal }}</div>
          </div>
        </div>
        <div class="qrCode" ref="qrCodeRef"></div>
      </div>
      <div class="referral-item">
        <div class="label">MH Markets Referral ID</div>
        <div class="content">
          <div class="text">{{ referralId }}</div>
          <div class="btn-copy" @click="handleCopy(referralId, $event)"><i class="icon-copy el-icon-copy-document"></i></div>
        </div>
      </div>
      <div class="referral-item link">
        <div class="label">MH Markets Referral Link</div>
        <div class="content">
          <div class="text">{{ referralLink }}</div>
          <div class="btn-copy" @click="handleCopy(referralLink, $event)"><i class="icon-copy el-icon-copy-document"></i></div>
        </div>
      </div>
      <div class="shared-apps">
        <div class="shared-item" @click="handleShare('facebook')"><img src="~@/assets/images/facebook.png"></div>
        <div class="shared-item" @click="handleShare('twitter')"><img src="~@/assets/images/twitter.png"></div>
        <div class="shared-item" @click="handleShare('whats')"><img src="~@/assets/images/whats.png"></div>
        <div class="shared-item" @click="handleShare('tele')"><img src="~@/assets/images/tele.png"></div>
        <!-- <div class="shared-item" @click="handleCopy"><img src="~@/assets/images/copy-link.png"></div> -->
        <div class="shared-item" @click="handleGeneratePoster"><img src="~@/assets/images/download.png"></div>
      </div>
    </div>
  </MhDialog>
</template>

<script>
import { mapState } from 'vuex'
import html2canvas from 'html2canvas'
import moment from 'moment'
import QRCode from 'qrcodejs2'
import clip from '@/utils/clipboard'

import MhDialog from '@/components/mh-dialog/index.vue'
import { shareToApps } from '@/utils/share'
export default {
  name: 'PosterPop',
  components: {
    MhDialog,
  },
  data() {
    return {
      qrcode: null
    }
  },
  computed: {
    ...mapState({
      activity: state => state.realtime.activity,
      referralId: state => state.realtime.referralId,
      referralLink: state => state.realtime.referralLink      
    }),
    getPrizeTotal() {
      return this.activity.prizeAmount?.toFixed(2)
    }
  },
  methods: {
    // 生成二维码
    generateQr() {
      if (this.qrcode) {
        this.qrcode.clear()
      }
      this.qrcode = new QRCode(this.$refs.qrCodeRef, {
        text: 'www.baidu.com',
        width: 60,
        height: 60,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.M
      })
    },
    show() {
      this.$refs.dialogRef.show()
      this.$nextTick(() => {
        this.generateQr()
      })
    },
    closeHandle(){
      this.$refs.dialogRef.hide()
    },
    handleShare(platform) {
      shareToApps(platform, this.referralLink)
    },
    handleCopy(copyText, $event) {
      clip(copyText, $event)
    },
    handleGeneratePoster() {
      html2canvas(this.$refs.htmlCanvas).then((canvas) => {
        const imgUrl = canvas.toDataURL('image/png')
        const a = document.createElement('a')
        a.href = imgUrl
        a.download = this.getImgName()
        a.click()
      })
    },
    getImgName() {
      return moment().format('YYYYMMDDHHmmssSSS') + '_share'
    }
  },
}
</script>

<style lang="scss" scoped>
.poster-pop {
  ::v-deep .el-dialog {
    border-radius: 12px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      .pop-wrap {
        width: 600px;
        height: 780px;
        background: linear-gradient(180deg, #313841 0%, #1F2227 100%);
        box-shadow: 0px 4px 10px 0px #00000040;
        border-radius: 12px;
        padding-top: 40px;
        position: relative;
        .btn-close {
          position: absolute;
          right: 20px;
          top: 20px;
          width: 40px;
          height: 40px;
          .icon-close {
            font-size: 40px;
            color: #fff;
          }          
        }
        .poster-wrap {
          position: relative;
          margin: 0 auto;
          margin-bottom: 60px;
          width: 420px;
          height: 440px;
          background-image: url("@/assets/images/poster.png");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          padding-top: 10px;
          padding-left: 16px;
          padding-right: 16px;
          .logo {
            width: 160px;
            height: 36px;
          }
          .curr-round {
            margin-top: 30px;
            display: flex;
            .item {
              text-align: center;
              .title {
                font-size: 16px;
                font-weight: 500;
                color: #C6222F;
              }
              .num {
                color: #fff;
                font-size: 48px;
                line-height: 58px;
                font-weight: 600;
              }
              &.round {
                width: 160px;
              }
              &.prize {
                flex: 1;
              }
            }
          }
          .qrCode {
            position: absolute;
            right: 20px;
            bottom: 30px;
            width: 68px;
            height: 68px;
            padding: 4px;
            background-color: #fff;
            border-radius: 6px;
          }
        }
        .referral-item {
          margin: 0 auto;
          width: 500px;
          height: 60px;
          border-radius: 6px;
          background: #FFFFFF33;
          padding-left: 20px;
          padding-right: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          color: #fff;
          &.link {
            margin-top: 10px;
          }
          .content {
            display: flex;
            .text {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              text-align: right;
              width: 200px;
            }
            .btn-copy {
              width: 20px;
              height: 20px;
              margin-left: 10px;
              .icon-copy {
                font-size: 20px;
              }
            }
          }
        }
        .shared-apps {
          margin: 0 auto;
          margin-top: 30px;
          width: 500px;
          display: flex;
          justify-content: center;
          gap: 30px;
          .shared-item {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}
</style>