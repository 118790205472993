import request from '@/utils/request'

// 用户登录
export function apiLogin(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data
  })
}

// 注册
export function apiRegister(data) {
  return request({
    url: '/api/user/register',
    method: 'post',
    data
  })
}