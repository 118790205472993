import moment from 'moment'
import momentTz from 'moment-timezone'

export function getGMT3Time(time) {
  return momentTz.tz(moment(time).format('YYYY-MM-DD HH:mm:ss'), 'Asia/Riyadh')
}

/**
 * 防抖
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * 分享功能
 * @param {String} text 
 */
export function shareToWhatsApp(text) {
  var url = `https://wa.me/?text=${encodeURIComponent(text)}`;
  if (navigator.userAgent.match(/android/i)) {
    // 对于Android设备
    window.open(url, '_blank');
  } else if (navigator.userAgent.match(/ipad|iphone/i)) {
    // 对于iOS设备
    window.open(url, '_blank');
  } else {
    // 对于其他设备
    alert('请手动复制链接到WhatsApp中分享: ' + url);
  }
}

/**
 * 跳转到用户后台中心
 * @param {String} token 
 */
export function goToUserBackend(token) {
  location.href = `http://www.baidu.com?token=${token}`
}