<template>
  <MhDialog 
    class="mh-login"
    ref="dialogRef"
    top="20px"
    :show-close="false"
    width="460px" 
  >
    <div class="btn-close" @click="closeHandle"><i class="icon-close el-icon-close"></i></div>
    <div class="tabs">
      <div class="tab-nav">
        <div :class="['tab-nav-item', {'active': selected === '1'}]" @click="handleNav('1')">Log in</div>
        <div :class="['tab-nav-item', {'active': selected === '2'}]" @click="handleNav('2')">Sign Up</div>
      </div>
      <div class="tab-panel">
        <LoginPanel v-if="selected === '1'" />
        <SignPanel v-else />
      </div>
      <div class="mh-intro">
        <p><span class="title">Disclaimer: </span>MHMarkets, Mohicans Marketing Management LLC, does not provide investment advisory services or guarantee returns. You are advised to exercise caution against fraudulent entities impersonating MHMarkets; we are not liable for losses from counterfeit websites. Trading in financial markets involves risks exceeding deposited funds and may not be suitable for all investors. MHMarkets is not responsible for losses due to technical glitches.</p>
      </div>
    </div>
  </MhDialog>
</template>

<script>
import { mapGetters } from 'vuex'

import MhDialog from '@/components/mh-dialog/index.vue'
import LoginPanel from './LoginPanel.vue'
import SignPanel from './SignPanel.vue'

export default {
  name: 'Mh-Login',
  components: {
    MhDialog,
    LoginPanel,
    SignPanel
  },
  computed: {
    ...mapGetters(['token'])
  },
  data() {
    return {
      selected: '1' // 1:登录,2:注册
    }
  },
  methods: {
    show(params) {
      this.selected = params.type || '1'
      this.$refs.dialogRef.show()
    },
    closeHandle() {
      this.$refs.dialogRef.hide()
    },
    handleNav(val) {
      this.selected = val
    }
  },
}
</script>

<style lang="scss" scoped>
.mh-login {
  ::v-deep .el-dialog {
    // height: 800px;
    border-radius: 16px;
    // background-image: url("@/assets/login-bg.png");
    background-color: rgba(255, 255, 255, 0.95);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding-top: 0;
      padding-top: 50px;
      padding-left: 50px;
      padding-right: 50px;
      position: relative;
      .btn-close {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 24px;
        height: 24px;
        .icon-close {
          font-weight: 500;
          font-size: 24px;
        }
      }
      .tabs {
        .tab-nav {
          user-select: none;
          padding-top: 10px;
          padding-bottom: 20px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          border-bottom: 2px solid #C6222F;
          .tab-nav-item {
            cursor: pointer;
            font-size: 24px;
            color: #ccc;
            line-height: 28px;
            &:hover {
              color: #C6222F;
            }
            &.active {
              font-weight: 700;
              color: #C6222F;
            }
          }
        }
        .mh-intro {
          .title {
            font-weight: 700;
          }
          margin-top: 50px;
          font-size: 12px;
          color: #000;
        }
      }
    }
  }
}
</style>