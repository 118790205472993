<template>
  <el-dialog
    class="mh-dialog" 
    v-bind="$attrs"
    :title="title"
    :width="width"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @closed="onClosed"
  >
    <slot slot="title" name="title" />
    <slot />
  </el-dialog>
</template>

<script>
export default {
  name: 'Mh-Dialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    onClosed() {
      this.$emit('closed')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>