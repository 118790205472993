<template>
  <div class="current-round">
    <PrizePool />
    <div class="current-round-view">
      <ProfitLeader />
      <VolumeLeader />
    </div>
  </div>
</template>

<script>
import PrizePool from '@/components/PrizePool/index.vue'
import ProfitLeader from './ProfitLeader.vue'
import VolumeLeader from './VolumeLeader.vue'
export default {
  name: 'CurrentRound',
  components: {
    PrizePool,
    ProfitLeader,
    VolumeLeader
  }
}
</script>

<style lang="scss" scoped>
.current-round {
  text-align: left;
  .current-round-view {
    margin: 0 auto;
    width: 1340px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
}
</style>